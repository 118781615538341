import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon, OtherAvailableFlags } from '@GDM/Icon';
import { COUNTRY_CODES } from '@utils/constants/countries';
import { isCountryCode } from '@utils/filters/isCountryCode';
import { TypeIcon } from '@utils/getTypeMeterIcons';
import classNames from 'classnames';
import { SearchbarOption } from 'pages/ui/Searchbar/SearchbarInput';
import { components, GroupBase, OptionProps } from 'react-select';
import styles from './styles.module.scss';

export const CustomOption: React.ComponentType<OptionProps<SearchbarOption, boolean, GroupBase<SearchbarOption>>> = (
  props,
) => {
  const country = isCountryCode(props?.data?.country ?? '') ? props?.data?.country : 'XX';
  const validCountries = [...COUNTRY_CODES, 'XX'];
  const countryIcon = country && validCountries.includes(country) ? country : OtherAvailableFlags.XX;

  return (
    <components.Option {...props}>
      <div
        className={classNames(props.isDisabled ? '' : 'text-default', 'd-flex align-items-center')}
        style={{ fontWeight: 500, cursor: props.isDisabled ? 'not-allowed' : 'pointer' }}
      >
        {props?.data?.country && <Icon name={countryIcon} size={12} className={classNames(styles.flag, 'mr-2')} />}
        {props?.data?.energy ? (
          <span style={{ width: '25px', display: 'inline-block' }}>
            <TypeIcon type={props.data.energy} />
          </span>
        ) : null}
        {props.data.label}
        {props.data?.status === 'draft' && <Badge className="ml-2" variant="danger" label="common.draft" />}
      </div>
    </components.Option>
  );
};
