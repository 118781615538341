import type { RequestError, RequestErrorBody } from '@hooks/useRequest';
import fetchWrapper from '@utils/fetch';
import { formatSystemDate } from '@utils/formatters';
import { v2_unavailabilities_path, v2_unavailability_path } from '@utils/routes';
import type { CapacityUnit, Unavailability } from '@utils/types/unavailability';

export const getUnavailabilities = async (params: { start_date: Date | null; end_date: Date | null }) => {
  const response = await fetchWrapper(
    v2_unavailabilities_path({
      params: {
        start_date: params.start_date ? formatSystemDate(params.start_date) : undefined,
        end_date: params.end_date ? formatSystemDate(params.end_date) : undefined,
      },
    }),
    {
      headers: { accept: 'application/json' },
    },
  );
  const unavailabilities: Unavailability[] = await response.json();

  return unavailabilities;
};

export type CreateUpdateUnavailabilityBody = {
  start_date: string;
  end_date: string;
  installation_uuid: string | null;
  event_type: Unavailability['event_type'] | null;
  p_max: number | null;
  capacity_unit: CapacityUnit | null;
  capacity_value: number | null;
  comment: string | null;
  windmill_ids: number[] | null;
};

export const createUnavailability = async (body: CreateUpdateUnavailabilityBody) => {
  const response = await fetchWrapper(v2_unavailabilities_path(), {
    body: JSON.stringify(body),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  let json: Unavailability | RequestErrorBody;

  try {
    json = await response.json();
  } catch (error) {
    throw new Error('errors.unknown_error');
  }

  if (!response.ok) {
    const code = 'code' in json && json.code ? json.code : 'errors.unknown_error';
    const message = 'message' in json && json.message ? json.message : 'errors.unknown_error';
    const errors = 'errors' in json && json.errors ? (json.errors as RequestError[]) : [];

    throw { code, message, errors } as RequestErrorBody;
  }

  return json as Unavailability;
};

export const updateUnavailability = async (body: CreateUpdateUnavailabilityBody, id: Unavailability['uuid']) => {
  const response = await fetchWrapper(v2_unavailability_path(id), {
    body: JSON.stringify(body),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });

  let json: Unavailability | RequestErrorBody;

  try {
    json = await response.json();
  } catch (error) {
    throw new Error('errors.unknown_error');
  }

  if (!response.ok) {
    const code = 'code' in json && json.code ? json.code : 'errors.unknown_error';
    const message = 'message' in json && json.message ? json.message : 'errors.unknown_error';
    const errors = 'errors' in json && json.errors ? (json.errors as RequestError[]) : [];

    throw { code, message, errors } satisfies RequestErrorBody;
  }

  return json as Unavailability;
};

export const destroyUnavailability = async (id: Unavailability['uuid']) => {
  const response = await fetchWrapper(v2_unavailability_path(id), { method: 'DELETE' });

  let json: Unavailability | RequestErrorBody;

  try {
    json = await response.json();
  } catch (error) {
    throw new Error('errors.unknown_error');
  }

  if (!response.ok) {
    const code = 'code' in json && json.code ? json.code : 'errors.unknown_error';
    const message = 'message' in json && json.message ? json.message : 'errors.unknown_error';
    const errors = 'errors' in json && json.errors ? (json.errors as RequestError[]) : [];

    throw { code, message, errors } satisfies RequestErrorBody;
  }

  return json as Unavailability;
};
