import React from 'react';
import { useServiceStatus } from '@hooks/requests/useServiceStatus';
import Page from '@pages/Page';
import { ServiceStatusTable } from '@pages/ServiceStatus/Table/ServiceStatusTable';
import { User } from '@utils/types/user';

export const ServiceStatus = ({ user }: { user: User }) => {
  const { data: service_status, isLoading, error } = useServiceStatus();

  return (
    <Page
      title="sidebar.admin_settings.service_status"
      error={error?.message}
      isLoading={isLoading}
      user={user}
      layout="no-background"
      fixedHeightContainer
      useQueryClient={false}
    >
      <div className="trade-container">
        {!isLoading && service_status && <ServiceStatusTable service_status={service_status} />}
      </div>
    </Page>
  );
};

export default ServiceStatus;
