import React, { useState } from 'react';
import { FilterContainer, Filters } from '@GDM/Filters';
import { CountrySelect } from '@GDM/forms';
import Page from '@pages/Page';
import { isCountryCode } from '@utils/filters/isCountryCode';
import { CountryCode } from '@utils/types/countries';
import { User } from '@utils/types/user';
import { NationalPricesContent } from './NationalPricesContent';

export const NationalPrices = ({ user }: { user: User }) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCode | null>(user.main_country || null);

  return (
    <Page
      user={user}
      title="common.national_prices"
      layout="no-background"
      header={
        <Filters className="px-0">
          <FilterContainer size="fit">
            <CountrySelect
              selectedOption={selectedCountry || null}
              onChange={(option) =>
                setSelectedCountry?.(option?.value && isCountryCode(option.value) ? option.value : null)
              }
            />
          </FilterContainer>
        </Filters>
      }
    >
      <NationalPricesContent country={selectedCountry} />
    </Page>
  );
};
