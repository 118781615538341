import React from 'react';
import { Icon } from '@GDM/Icon';
import { CellContext } from '@tanstack/react-table';
import { ServiceStatus } from '@utils/types/service-status';

export const Up = ({ row: { original } }: CellContext<ServiceStatus, unknown>) => {
  const iconName = original.up ? 'CheckCircle' : 'AlertTriangle';
  const variant = original.up ? 'text-green' : 'text-red';

  return <Icon size={16} name={iconName} className={variant} />;
};
