import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert } from '@GDM/Alert';
import { Tab, Tabs } from '@GDM/Tabs';
import { tabsContext } from '@GDM/Tabs/tabs.context';
import { useInstallationsLegacy } from '@hooks/requests/useInstallations';
import { useMandates } from '@hooks/requests/useMandates';
import { useMeterTypes } from '@hooks/requests/useMeterTypes';
import { useRenewMessage } from '@hooks/requests/useRenewMessage';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { InstallationWithMeterInfo as Installation } from '@utils/types/installation';
import { Mandate } from '@utils/types/mandate';
import { User } from '@utils/types/user';
import { installationsPageContext, InstallationsPageContext } from './context/installations-page.context';
import styles from './installations.module.scss';
import { InstallationsActions } from './InstallationsActions';
import { InstallationsFilters, InstallationFiltersType } from './InstallationsFilters';
import { DataLoggersTab } from './tabs/DataLoggersTab';
import { InstallationsTab } from './tabs/InstallationsTab';
import { MandatesTab } from './tabs/MandatesTab';
import { MandateFilter } from './tabs/MandatesTab/MandatesFilters/MandatesFilter';
import { OwnerCsvButton } from './tabs/OwnersTab/OwnerButton/OwnerCsvButton';
import { OwnerProvider } from './tabs/OwnersTab/OwnerProvider';
import { OwnersTab } from './tabs/OwnersTab/OwnersTab';
import { OwnersFilters } from './tabs/OwnersTab/OwnersTable/OwnersFilters/OwnerFilters';

const InstallationsPage = ({ user }: { user: User }) => {
  const { t } = useTranslation(user.locale);
  const tabs: Tab<string>[] = [
    { id: 'installations', name: 'common.installations' },
    { id: 'mandates', name: 'common.mandates' },
    {
      id: 'owners',
      name: 'common.owners',
      permissions: ['display:view_owner'],
      condition: user.account_type !== 'aggregator',
    },
    { id: 'data_loggers', name: 'common.data_loggers' },
  ];
  const [filters, setFilters] = useState<InstallationFiltersType>({
    name: '',
    type: 'all',
    status: null,
    country: null,
  });

  const [dataLoggerModalVisible, setDataLoggerModalVisible] = useState(false);
  const [mandateModalVisible, setMandateModalVisible] = useState(false);

  const toggleDataLoggerModal = useCallback(
    () => setDataLoggerModalVisible((prevModalVisible) => !prevModalVisible),
    [],
  );
  const toggleMandateModal = useCallback(() => setMandateModalVisible((prevModalVisible) => !prevModalVisible), []);

  const installationsRequest = useInstallationsLegacy({ type: 'operational_with_draft', withMeterInfo: true });
  const [installations, setInstallations] = useState<Installation[]>([]);
  const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null);
  const [filteredInstallations, setFilteredInstallations] = useState<Installation[]>([]);

  const reqMeterTypes = useMeterTypes();
  const reqRenewMessage = useRenewMessage();

  const mandatesRequest = useMandates();
  const [mandates, setMandates] = useState<Mandate[]>([]);
  const [filteredMandates, setFilteredMandates] = useState<Mandate[]>([]);

  const applyFilter = useCallback((filters: InstallationFiltersType, installations: Installation[]) => {
    let _filteredInstallations: Installation[] = installations;
    if (filters.name) {
      _filteredInstallations = matchSorterInstanceStrict(installations || [], filters.name, {
        keys: ['name', 'prm'],
      });
    }

    if (filters.country) {
      _filteredInstallations = _filteredInstallations.filter(
        (installation) => installation?.country === filters.country,
      );
    }

    if (filters.type !== 'all') {
      _filteredInstallations = _filteredInstallations.filter((installation) => installation?.energy === filters.type);
    }

    if (filters.status) {
      _filteredInstallations = _filteredInstallations.filter((installation) => installation?.status === filters.status);
    }

    setFilteredInstallations(_filteredInstallations);
  }, []);

  const addMandate = useCallback((mandate: Mandate) => {
    setFilteredMandates((previousMandates) => {
      const clonedMandates = [...previousMandates];

      const mandateIndex = clonedMandates.findIndex((m) => m.uuid === mandate?.uuid);

      if (mandateIndex > -1) {
        clonedMandates[mandateIndex] = mandate;
      } else {
        clonedMandates.push(mandate);
        clonedMandates.sort((a, b) => (a.representative > b.representative ? 1 : -1));
      }

      return clonedMandates;
    });
  }, []);

  const addInstallation = useCallback((installation: Installation | null) => {
    setInstallations((prevInstallations) => {
      if (!installation) return prevInstallations;
      const clonedInstallations = [...prevInstallations];
      const installationIndex = clonedInstallations.findIndex((m) => m.uuid === installation?.uuid);
      if (installationIndex > -1) {
        clonedInstallations[installationIndex] = installation;
      } else {
        clonedInstallations.push(installation);
      }

      return clonedInstallations;
    });
  }, []);

  const deleteInstallation = useCallback((installation: Installation) => {
    setInstallations((prevInstallations) => {
      const clonedInstallations = [...prevInstallations];
      const installationIndex = clonedInstallations.findIndex((m) => m.uuid === installation.uuid);
      if (installationIndex > -1) {
        return clonedInstallations.filter((_, idx) => installationIndex !== idx);
      } else {
        return prevInstallations;
      }
    });
  }, []);

  const addMeterInfo = useCallback((installationUuid: Installation['uuid'], meterInfo: Installation['meter']) => {
    setInstallations((prevInstallations) => {
      const clonedInstallations = [...prevInstallations];
      const installationIndex = clonedInstallations.findIndex((m) => m.uuid === installationUuid);
      if (installationIndex > -1) {
        const installation: Installation = clonedInstallations[installationIndex];
        clonedInstallations[installationIndex] = { ...installation, meter: meterInfo };
      }

      return clonedInstallations;
    });
  }, []);

  useEffect(() => {
    applyFilter(filters, installations);
  }, [filters, installations, applyFilter]);

  useEffect(() => {
    if (mandatesRequest.data) {
      const fetchedMandates =
        mandatesRequest.data?.sort((a, b) => (a.representative > b.representative ? 1 : -1)) || [];
      setFilteredMandates(fetchedMandates);
      setMandates(fetchedMandates);
    }
  }, [mandatesRequest.data]);

  useEffect(() => {
    if (installationsRequest.loaded) {
      if (installationsRequest.data) {
        setInstallations(installationsRequest.data);
        applyFilter(filters, installationsRequest.data);
      } else {
        setInstallations([]);
        setFilteredInstallations([]);
      }
    }
  }, [installationsRequest.data, installationsRequest.loaded, filters, applyFilter]);

  const contextValue = useMemo<InstallationsPageContext>(() => {
    const value: InstallationsPageContext = {
      loading: mandatesRequest.loading || installationsRequest.loading,
      filters,
      setFilters,
      meterTypes: reqMeterTypes.data || [],
      mandates,
      addMandate,
      filteredMandates,
      setFilteredMandates,
      mandateModalVisible,
      toggleMandateModal,
      installations,
      selectedInstallation,
      setSelectedInstallation,
      filteredInstallations,
      setFilteredInstallations,
      addInstallation,
      addMeterInfo,
      deleteInstallation,
      dataLoggerModalVisible,
      toggleDataLoggerModal,
    };

    return value;
  }, [
    mandates,
    addMandate,
    mandatesRequest.loading,
    mandateModalVisible,
    toggleMandateModal,
    filteredMandates,
    installations,
    addInstallation,
    addMeterInfo,
    deleteInstallation,
    selectedInstallation,
    filteredInstallations,
    installationsRequest.loading,
    filters,
    dataLoggerModalVisible,
    toggleDataLoggerModal,
    reqMeterTypes.data,
  ]);

  return (
    <Page
      title={t('common.installations')}
      user={user}
      isLoading={mandatesRequest.loading ?? installationsRequest.loading}
    >
      <OwnerProvider>
        <installationsPageContext.Provider value={contextValue}>
          {reqRenewMessage.data?.renew_message && <Alert className="mt-3">{reqRenewMessage.data?.renew_message}</Alert>}
          <Tabs tabs={tabs} className="mb-3">
            <tabsContext.Consumer>
              {({ currentTab }) => {
                return (
                  <>
                    <>
                      {currentTab === 'installations' && <InstallationsFilters />}
                      {currentTab === 'mandates' && <MandateFilter />}
                      {currentTab === 'owners' && <OwnersFilters />}
                      {currentTab === 'owners' && <OwnerCsvButton />}
                      {currentTab !== 'owners' && <InstallationsActions activeTab={currentTab as string} />}
                    </>
                    <div className={styles['tables-container']}>
                      {currentTab === 'installations' && <InstallationsTab />}
                      {currentTab === 'mandates' && <MandatesTab />}
                      {currentTab === 'data_loggers' && <DataLoggersTab />}
                      {currentTab === 'owners' && <OwnersTab />}
                    </div>
                  </>
                );
              }}
            </tabsContext.Consumer>
          </Tabs>
        </installationsPageContext.Provider>
      </OwnerProvider>
    </Page>
  );
};

export default InstallationsPage;
