import {
  getUnavailabilities,
  type CreateUpdateUnavailabilityBody,
  createUnavailability,
  updateUnavailability,
  destroyUnavailability,
} from '@api/unavailabilities';
import type { CreateModalForm } from '@pages/Unavailabilities/CreateModal/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { Unavailability } from '@utils/types/unavailability';
import type { RequestErrorBody } from '..';

export const useUnavailabilities = (params: { start_date: Date | null; end_date: Date | null }) => {
  return useQuery({
    queryKey: ['unavailabilities', params],
    queryFn: () => getUnavailabilities(params),
  });
};

export const useUnavailabilityCreateMutation = (
  params: { start_date: Date | null; end_date: Date | null },
  values: CreateModalForm,
) => {
  const queryClient = useQueryClient();

  return useMutation<Unavailability, RequestErrorBody, CreateUpdateUnavailabilityBody>({
    mutationKey: ['unavailabilities', values],
    mutationFn: (body) => createUnavailability(body),
    onSuccess: (unavailability) => {
      queryClient.setQueryData<Unavailability[]>(['unavailabilities', params], (previousUnavailabilities) => {
        const clonedUnavailabilities = previousUnavailabilities ? [...previousUnavailabilities] : [];
        clonedUnavailabilities?.push(unavailability);

        return clonedUnavailabilities;
      });
    },
  });
};

export const useUnavailabilityUpdateMutation = (
  params: { start_date: Date | null; end_date: Date | null },
  values: CreateModalForm,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    Unavailability,
    RequestErrorBody,
    { body: CreateUpdateUnavailabilityBody; uuid: Unavailability['uuid'] }
  >({
    mutationKey: ['unavailabilities', values],
    mutationFn: ({ body, uuid }) => updateUnavailability(body, uuid),
    onSuccess: (unavailability) => {
      queryClient.setQueryData<Unavailability[]>(['unavailabilities', params], (previousUnavailabilities) => {
        return previousUnavailabilities?.map((prevUnavailability) =>
          unavailability.uuid === prevUnavailability.uuid ? unavailability : prevUnavailability,
        );
      });
    },
  });
};

export const useUnavailabilityCancelMutation = (params: { start_date: Date | null; end_date: Date | null }) => {
  const queryClient = useQueryClient();

  return useMutation<Unavailability, RequestErrorBody, string>({
    mutationKey: ['unavailabilities', params],
    mutationFn: (uuid) => destroyUnavailability(uuid),
    onSuccess: (unavailability) => {
      queryClient.setQueryData<Unavailability[]>(['unavailabilities', params], (previousUnavailabilities) => {
        return previousUnavailabilities?.map((prevUnavailability) =>
          unavailability.uuid === prevUnavailability.uuid ? unavailability : prevUnavailability,
        );
      });
    },
  });
};
