import React, { useEffect, useState } from 'react';
import { Alert } from '@GDM/Alert';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Select } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { useInstallationsLegacy } from '@hooks/requests/useInstallations';
import { put, useRequest } from '@hooks/useRequest';
import { v2_ppa_simulation_change_installation_path } from '@utils/routes';
import classNames from 'classnames';
import { CompareChange } from './CompareChange';
import styles from './installation-informations.module.scss';

export const InstallationChangeModal = ({ installationId }: { installationId: string }) => {
  const { data: installations, loading } = useInstallationsLegacy({ type: 'all' });
  const [selectedInstallationId, setSelectedInstallationId] = useState<string | null>(null);
  const [prevSelectedInstallationId, setPrevSelectedInstallationId] = useState<string | null>(null);
  const selectedInstallation = installations?.find((installation) => installation.uuid === selectedInstallationId);
  const prevSelectedInstallation = installations?.find((installation) => installation.uuid === installationId);
  const req = useRequest(
    v2_ppa_simulation_change_installation_path(prevSelectedInstallation?.name || 'none'),
    put,
    true,
  );

  if (prevSelectedInstallationId !== installationId) {
    setSelectedInstallationId(installationId);
    setPrevSelectedInstallationId(installationId);
  }

  const options =
    installations?.map((installation) => ({
      value: installation.uuid,
      label: installation.name,
    })) ?? [];

  const handleSave = () => {
    const data = { new_installation_uuid: selectedInstallationId };

    req.execute?.({ data });
  };

  useEffect(() => {
    if (req.loaded) {
      const simulationId = new URLSearchParams(window.location.search).get('ppa_simulation_id');

      window.location.href = `/v2/ppa_projects/${encodeURIComponent(
        selectedInstallation?.name ?? 'none',
      )}?ppa_simulation_id=${simulationId}`;
    }
  }, [req, selectedInstallation?.name]);

  return (
    <>
      <DefinitionModalBody>
        <Select
          isLoading={loading}
          options={options}
          onChange={(option) => setSelectedInstallationId(option?.value || null)}
          selectedOption={selectedInstallationId}
          isDisabled={req.loading}
        />

        {req.error?.message && <Alert full variant="danger" label={req.error?.message} className="mt-3" />}

        {prevSelectedInstallation &&
          selectedInstallation &&
          prevSelectedInstallation?.uuid !== selectedInstallation?.uuid && (
            <div>
              <Text
                text="pricing.project.name_coordinates_change_warning"
                type="danger"
                className={classNames('my-3', styles['long-text'])}
              />

              <CompareChange
                label="common.name"
                oldValue={prevSelectedInstallation.name}
                newValue={selectedInstallation.name}
              />
              <CompareChange
                label="common.latitude"
                oldValue={prevSelectedInstallation.latitude}
                newValue={selectedInstallation.latitude}
              />
              <CompareChange
                label="common.longitude"
                oldValue={prevSelectedInstallation.longitude}
                newValue={selectedInstallation.longitude}
              />

              <Text text="pricing.project.confirm_merge" type="danger" className="mt-3" />
            </div>
          )}
      </DefinitionModalBody>
      <DefinitionModalFooter save={handleSave} isLoading={req.loading} closeOnSave={false} />
    </>
  );
};
