import React from 'react';
import { DateCell, HeadCell } from '@GDM/Table';
import { ManualFix } from '@pages/ServiceStatus/Table/Cells/ManualFix';
import { Name } from '@pages/ServiceStatus/Table/Cells/Name';
import { Up } from '@pages/ServiceStatus/Table/Cells/Up';
import { ColumnDef } from '@tanstack/react-table';
import { ServiceStatus } from '@utils/types/service-status';

export const SERVICE_STATUS_COLUMNS = {
  NAME: {
    header: () => <HeadCell label="common.name" />,
    cell: Name,
    accessorKey: 'name',
  },

  UP: {
    header: () => <HeadCell label="common.status.title" />,
    cell: Up,
    accessorKey: 'up',
  },

  ERROR_NUMBER: {
    header: () => <HeadCell label="common.errors" />,
    accessorKey: 'error_number',
  },

  LAST_STATUS_AT: {
    header: () => <HeadCell label="common.since" />,
    cell: ({ getValue }) => <DateCell getValue={getValue} />,
    accessorKey: 'last_status_at',
  },

  MANUAL_FIX: {
    header: () => <HeadCell label="common.fix" />,
    cell: ManualFix,
    accessorKey: 'id',
  },
} as const satisfies Record<string, ColumnDef<ServiceStatus>>;
