import { getAllInstallations } from '@api/installations';
import { useRequest } from '@hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import type { ReqError } from '@utils/fetch';
import { all_v2_installations_path } from '@utils/routes';
import { Installation, InstallationWithMeterInfo } from '@utils/types/installation';

export type Options = {
  type: 'all' | 'lead' | 'operational' | 'operational_with_draft' | 'with_simulations';
  includeInactive?: boolean;
  withMeterInfo?: boolean;
  withWindmills?: boolean;
};

/**
 * @param options.type
 * `all` = All Installations, `lead` = Installation not managed on a day to day basis, `operational` = Monitoring installations, `operational_with_draft` = same as `operational` but includes draft installations, `with_simulations` = Installations including pricing simulations
 * @deprecated
 */
export function useInstallationsLegacy<T extends Options>(options?: T) {
  const req = useRequest<(T['withMeterInfo'] extends true ? InstallationWithMeterInfo : Installation)[]>(
    all_v2_installations_path({
      type: options?.type || 'all',
      ...(options?.includeInactive ? { include_inactive: true } : {}),
      ...(options?.withMeterInfo ? { with_meter_info: true } : {}),
    }),
    'GET',
  );

  return req;
}

/**
 * @param options.type
 * `all` = All Installations, `lead` = Installation not managed on a day to day basis, `operational` = Monitoring installations, `operational_with_draft` = same as `operational` but includes draft installations, `with_simulations` = Installations including pricing simulations
 */
export function useInstallations<T extends Options>(options?: T) {
  return useQuery<Array<T['withMeterInfo'] extends true ? InstallationWithMeterInfo : Installation>, ReqError>({
    queryKey: ['installations', options],
    queryFn: () => getAllInstallations(options),
  });
}
