import React, { useMemo } from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button/Button';
import { styles } from '@GDM/Filters';
import { NumberCell } from '@GDM/Table/Cells/NumberCell/NumberCell';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableFooter } from '@GDM/Table/TableFooter/TableFooter';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import { AcceptableUnits } from '@GDM/ValueWithUnit';
import { tString } from '@hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';
import { AccessorKeyColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import fetchWrapper from '@utils/fetch';
import { v2_green_star_counter_parts_path } from '@utils/routes';
import debounce from 'lodash/debounce';
import { UseFormReturn } from 'react-hook-form';
import { useGreenStarGroups } from './queries';
import { RiskAnalysisCounterpart, RiskAnalysisCounterpartsFilter } from './types';

export const Counterparts = ({ form: { control, watch } }: { form: UseFormReturn<RiskAnalysisCounterpartsFilter> }) => {
  const { spv, grappe, holding, apply_ownership_percentage } = watch();
  const options = { spv, grappe, holding, apply_ownership_percentage };
  const groupsQuery = useGreenStarGroups({ grappe, spv, holding }, true);

  const grappeOptions =
    groupsQuery.data?.grappes
      .map((grappe) => ({
        value: grappe,
        label: grappe,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const holdingOptions =
    groupsQuery.data?.holdings
      .map((holding) => ({
        value: holding,
        label: holding,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const spvOptions =
    groupsQuery.data?.spvs
      .map((spv) => ({
        value: spv,
        label: spv,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const url = v2_green_star_counter_parts_path(options);

  const counterpartsQuery = useQuery({
    queryKey: ['greenstar_countereparts', url],
    queryFn: async (): Promise<RiskAnalysisCounterpart[]> => {
      const response = await fetchWrapper(url);

      return response.json();
    },
  });

  return (
    <div className="p-4">
      <form className="d-flex justify-content-between mb-4">
        <div className="d-flex gap-4">
          <ControlledSelect
            isMulti
            name="spv"
            control={control}
            options={spvOptions}
            placeholder="sales_management.owner"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="grappe"
            control={control}
            options={grappeOptions}
            placeholder="risk_analysis.grappe"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="holding"
            control={control}
            options={holdingOptions}
            placeholder="risk_analysis.holding"
            className={styles.select}
          />
          <ControlledSelect
            name="apply_ownership_percentage"
            control={control}
            options={[
              { label: '100 %', value: false },
              { label: 'risk_analysis.consolidated', value: true },
            ]}
            placeholder="risk_analysis.vision"
            className={styles.select}
            defaultValue={false}
          />
        </div>
        <div className="d-flex">
          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
            onClick={debounce(() => {
              window.location.href = v2_green_star_counter_parts_path({ ...options, format: 'xls' });
            })}
          />
        </div>
      </form>
      <CounterPartsTable rows={counterpartsQuery.data} isLoading={counterpartsQuery.isPending} />
    </div>
  );
};

const CounterPartsTable = ({ rows = [], isLoading }: { rows?: RiskAnalysisCounterpart[]; isLoading: boolean }) => {
  const columns = useMemo<AccessorKeyColumnDef<RiskAnalysisCounterpart>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'risk_analysis.counterpart',
      },
      {
        id: 'siren',
        accessorKey: 'siren',
        header: 'common.siren',
      },
      {
        id: 'debt',
        accessorKey: 'debt',
        header: 'risk_analysis.unpaid_to_date',
        cell: ({ getValue }) => <NumberCell value={getValue()} unit={'M€' as AcceptableUnits} fractionDigits={3} />,
      },
      {
        id: 'exposure',
        accessorKey: 'exposure',
        header: 'risk_analysis.exposure',
        cell: ({ getValue }) => <NumberCell value={getValue()} unit={'M€' as AcceptableUnits} fractionDigits={3} />,
      },
      {
        id: 'guarantee',
        accessorKey: 'guarantee',
        header: 'risk_analysis.guarantee',
        cell: ({ getValue }) => <NumberCell value={getValue()} unit={'M€' as AcceptableUnits} fractionDigits={3} />,
      },
      {
        id: 'residual_exposure',
        accessorKey: 'residual_exposure',
        header: 'risk_analysis.residual_exposing',
        cell: ({ getValue }) => <NumberCell value={getValue()} unit={'M€' as AcceptableUnits} fractionDigits={3} />,
      },
      {
        id: 'rating',
        accessorKey: 'rating',
        header: 'risk_analysis.rating',
      },
      {
        id: 'collateral',
        accessorKey: 'collateral',
        header: 'risk_analysis.collateral',
      },
      {
        id: 'termination_period_in_month',
        accessorKey: 'termination_period_in_month',
        header: 'risk_analysis.termination',
        cell: ({ getValue }) => <NumberCell value={getValue()} />,
      },
    ],
    [],
  );

  const { totalRow } = useMemo(() => {
    const totalRow = {
      name: 'Total Volume',
      unit: 'M€',
      subRows: [],
      values: columns.reduce((vls, col) => {
        const isEuro =
          col.accessorKey === 'debt' ||
          col.accessorKey === 'exposure' ||
          col.accessorKey === 'guarantee' ||
          col.accessorKey === 'residual_exposure';

        if (isEuro) {
          let sum = 0;
          rows.forEach((row) => {
            sum += Number(row[col.accessorKey as keyof RiskAnalysisCounterpart]) || 0;
          });

          return { ...vls, [col.accessorKey]: sum };
        } else {
          return { ...vls, [col.accessorKey]: '' };
        }
      }, {} as Record<string, number | string>),
    };

    return { totalRow };
  }, [columns, rows]);

  const { locale } = useUser();
  const t = tString(locale);

  const actualColumns = columns.map((column) => ({
    ...column,
    footer: () =>
      column.id === 'name' ? (
        <div className={styles['footer-wrapper']}>
          <div className={styles['footer-row']}>{t('risk_analysis.total_amount')}</div>
        </div>
      ) : (
        <div className={styles['footer-wrapper']}>
          <div className={styles['footer-row']}>
            <NumberCell value={totalRow.values[column.accessorKey]} unit={'M€' as AcceptableUnits} fractionDigits={2} />
          </div>
        </div>
      ),
  }));

  const table = useReactTable({
    columns: actualColumns,
    data: rows,
    // sortDescFirst is required to avoid excessive Tanstack table rerender
    // https://github.com/TanStack/table/issues/4289#issuecomment-2052735066
    sortDescFirst: true,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table className="text-small">
      <TableHead table={table} />
      <TableBody table={table} isLoading={isLoading} />
      <TableFooter table={table} />
    </Table>
  );
};
