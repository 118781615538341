import React from 'react';
import { Restricted } from '@components/Restricted';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { NoWrapHeadCell } from '@GDM/Table';
import { ColumnDef } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';
import { User } from '@utils/types/user';
import { CapacityValueCell } from './cells/CapacityValueCell';
import { EventTypeCell } from './cells/EventTypeCell';
import { InstallationCell } from './cells/InstallationCell';
import { PmaxCell } from './cells/PmaxCell';
import { SharingCell } from './cells/SharingCell';
import { SourceCell } from './cells/SourceCell';
import { StatusCell } from './cells/StatusCell';
import { TimeCell } from './cells/TimeCell';

export type ColumnWithClassName = ColumnDef<Unavailability> & { className?: string };

export const getColumns = ({
  onEditClick,
  onCancelClick,
  user,
}: {
  onEditClick: (id: Unavailability['uuid'] | null) => void;
  onCancelClick: (id: Unavailability['uuid'] | null) => void;
  user: User;
}): ColumnWithClassName[] => {
  const cols: ColumnWithClassName[] = [
    {
      accessorFn: (unavailability) => unavailability.installation.name,
      header: 'common.installation',
      cell: InstallationCell,
      id: 'installation_name',
    },
    {
      header: () => <NoWrapHeadCell label="common.wind.turbine" />,
      id: 'windmills',
      cell: ({ row }) => (
        <div className="d-flex gap-2">
          {row.original.windmills?.map((windmill) => (
            <Badge key={windmill.id} label={windmill.name || windmill.turbine.name} />
          ))}
        </div>
      ),
    },
    { header: () => <NoWrapHeadCell label="common.start_date" />, accessorKey: 'start_date', cell: TimeCell },
    { header: () => <NoWrapHeadCell label="common.end_date" />, accessorKey: 'end_date', cell: TimeCell },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.capacity_value" tooltip="MW" />,
      cell: CapacityValueCell,
      accessorKey: 'real_capacity_value',
    },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.p_max" tooltip="MW" />,
      cell: PmaxCell,
      accessorKey: 'p_max',
    },
    {
      header: () => <NoWrapHeadCell label="monitoring.invoicing.source" />,
      accessorKey: 'source',
      cell: SourceCell,
    },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.event_type" />,
      accessorKey: 'event_type',
      cell: EventTypeCell,
    },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.comment" />,
      accessorKey: 'comment',
    },
    {
      header: () => (
        <NoWrapHeadCell
          label={
            user.account_type == 'aggregator'
              ? 'monitoring.unavailabilities.producer'
              : 'monitoring.unavailabilities.aggregator'
          }
          tooltip="monitoring.unavailabilities.warnings.counterpart_null_case"
        />
      ),
      accessorKey: 'counterpart_name',
    },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.sharing_status" />,
      accessorKey: 'sharing_status',
      cell: SharingCell,
    },
    {
      header: () => <NoWrapHeadCell label="common.status_label" />,
      accessorKey: 'status',
      cell: StatusCell,
    },
    {
      header: () => <NoWrapHeadCell label="common.updated_at" />,
      accessorKey: 'updated_at',
      cell: TimeCell,
    },
    {
      header: () => <></>,
      id: 'actions',
      enableSorting: false,
      cell: ({ row }) => (
        <Restricted permissions={['display:edit_monitoring']}>
          <Button
            className="ml-2"
            onClick={() => onEditClick(row.original.uuid)}
            icon="Edit3"
            variant="primary-2"
            floating
          />
          <Button
            className="ml-2"
            onClick={() => onCancelClick(row.original.uuid)}
            icon="Trash2"
            variant="secondary"
            floating
          />
        </Restricted>
      ),
    },
  ];

  return cols;
};
