import React, { useState, useMemo, useEffect } from 'react';
import { useUser } from '@context/User.context';
import { useBookPlayers } from '@hooks/requests/useBookPlayers';
import { useInstallationPlayers } from '@hooks/requests/useInstallationPlayers';
import { useRequest } from '@hooks/useRequest';
import { useDashboardMeter } from '@pages/DashboardMeter/useDashboardMeter';
import { revenuePlayers } from '@utils/filters/revenuePlayers';
import { Currency } from '@utils/types/currency';
import MarketPlayer from '@utils/types/market_player';
import sortBy from 'lodash/sortBy';
import { serializeSearchParams } from '../helpers/serializeSearchParams';
import { sortAndFilterGroupedData } from '../helpers/sortAndFilterGroupedData';
import { isGroupedData } from '../helpers/type-helpers';
import { InvoiceRevenueRequestParameters, RevenueContextType } from '../revenue.types';
import { getDefaultDateValues } from './default-values';
import { REVENUE_SHOW_HELP_LOCAL_STORAGE_KEY } from './revenue-consts';
import { REVENUE_URLS } from './revenue-urls';
import { revenueContext } from './revenue.context';

export const RevenueProvider = ({
  children,
  sourceType,
  identifier,
  marketPlayer,
}: {
  sourceType: RevenueContextType['sourceType'];
  identifier?: string;
  marketPlayer?: MarketPlayer;
  children: React.ReactNode;
}) => {
  const { isAuthorized, currencies } = useUser();
  const { meter } = useDashboardMeter();
  const { DEFAULT_START_DATE, DEFAULT_END_DATE } = getDefaultDateValues(
    isAuthorized(['display:forecasted_revenues']) && meter?.has_bp,
  );
  const [startDate, setStartDate] = useState<RevenueContextType['startDate']>(DEFAULT_START_DATE);
  const [endDate, setEndDate] = useState<RevenueContextType['endDate']>(DEFAULT_END_DATE);
  const [displayMode, setDisplayMode] = useState<RevenueContextType['displayMode']>('chart');
  const [resolution, setResolution] = useState<RevenueContextType['resolution']>('monthly');
  const [groupBy, setGroupBy] = useState<RevenueContextType['groupBy']>();
  const [groupByMonth, setGroupByMonth] = useState<RevenueContextType['groupByMonth']>(false);
  const [selectedPlayers, setSelectedPlayers] = useState<RevenueContextType['selectedPlayers']>([]);
  const [topInstallationsLimit, setTopInstallationsLimit] = useState<RevenueContextType['topInstallationsLimit']>(7);
  const [showHelp, setShowHelp] = useState<RevenueContextType['showHelp']>(
    localStorage.getItem(REVENUE_SHOW_HELP_LOCAL_STORAGE_KEY) !== 'off',
  );
  const user = useUser();
  const [selectedCurrency, setSelectedCurrency] = useState<RevenueContextType['selectedCurrency']>(
    user.currencies?.[0] || undefined,
  );

  // Players
  const { data: installationPlayers } = useInstallationPlayers(
    sourceType === 'installation' && identifier ? identifier : null,
  );
  const { data: bookPlayers } = useBookPlayers(sourceType === 'book' && identifier ? identifier : null);

  const params: InvoiceRevenueRequestParameters = useMemo(
    () =>
      serializeSearchParams({
        resolution,
        group: groupBy,
        start: startDate,
        end: endDate,
        market_player_ids: selectedPlayers ?? [],
        currency: selectedCurrency ?? 'EUR',
      }),
    [endDate, groupBy, resolution, selectedPlayers, startDate, selectedCurrency],
  );

  // Data
  const {
    data: invoiceRevenueData,
    loading: revenueIsLoading,
    execute,
  } = useRequest<
    {
      currency: Currency;
      data: { overview: RevenueContextType['overview']; revenues: RevenueContextType['revenue'] };
    }[]
  >(
    // Choose url based on if it's installation/book/market player
    sourceType && identifier ? REVENUE_URLS[sourceType](identifier) : '',
    'GET',
    true,
  );

  useEffect(() => {
    // Only execute if there's a start AND end date.
    if (startDate && endDate) {
      execute?.({ params });
    }
  }, [endDate, execute, params, startDate]);

  useEffect(() => {
    if (invoiceRevenueData?.length && !selectedCurrency) {
      setSelectedCurrency(invoiceRevenueData[0].currency);
    }
  }, [invoiceRevenueData, selectedCurrency]);

  const filteredAndSortedRevenue = useMemo(() => {
    const revenues = invoiceRevenueData?.[0]?.data?.revenues ?? [];

    if (isGroupedData(revenues)) {
      return sortAndFilterGroupedData(revenues, topInstallationsLimit);
    } else {
      return revenues;
    }
  }, [invoiceRevenueData, topInstallationsLimit]);

  const overview = invoiceRevenueData?.[0].data.overview;

  const revenueContextValue = useMemo<RevenueContextType>(() => {
    const concatenatedPlayers = [...(installationPlayers ?? []), ...(bookPlayers ?? [])].filter(revenuePlayers);
    const players = marketPlayer ? [] : sortBy<MarketPlayer>(concatenatedPlayers, ['player_type', 'short_name']);
    const owners = players?.filter((player) => player.player_type === 'owner');

    return {
      displayMode,
      endDate,
      groupBy,
      isLoading: revenueIsLoading,
      marketPlayer,
      overview,
      owners,
      players,
      resolution,
      revenue: filteredAndSortedRevenue,
      selectedPlayers,
      setDisplayMode,
      setEndDate,
      setGroupBy,
      setResolution,
      setSelectedPlayers,
      setShowHelp,
      setStartDate,
      setTopInstallationsLimit,
      showHelp,
      sourceType,
      startDate,
      topInstallationsLimit,
      currencies,
      selectedCurrency,
      setSelectedCurrency,
      groupByMonth,
      setGroupByMonth,
    };
  }, [
    bookPlayers,
    displayMode,
    endDate,
    filteredAndSortedRevenue,
    groupBy,
    installationPlayers,
    overview,
    marketPlayer,
    resolution,
    revenueIsLoading,
    selectedPlayers,
    showHelp,
    sourceType,
    startDate,
    topInstallationsLimit,
    currencies,
    selectedCurrency,
    setSelectedCurrency,
    groupByMonth,
    setGroupByMonth,
  ]);

  return <revenueContext.Provider value={revenueContextValue}>{children}</revenueContext.Provider>;
};
