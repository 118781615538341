import React, { useEffect, useMemo, useState } from 'react';
import { mandatesContext } from '@context/Mandates.context';
import { Badge } from '@GDM/Badge';
import { ERRORS_TO_REMAP } from '@GDM/FormErrors/errors-to-remap';
import { Form } from '@GDM/forms';
import { useGrids } from '@hooks/requests/useGrids';
import { useMandates } from '@hooks/requests/useMandates';
import { useInstallationFormQuery } from '@pages/Installations/Installation/useInstallationFormQuery';
import { edit_v2_installation_path, v2_installations_path } from '@utils/routes';
import { User } from '@utils/types/user';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isEnedisLike } from '../isEnedisLike';
import { COMMON_FIELDS } from './constants/common-fields';
import { FIELD_SECTIONS } from './constants/field-sections';
import { showMeterFormContext } from './context/showMeterForm.context';
import { useErrorsFromRequest } from './hooks/useErrorsFromRequest';
import { useFormDataProviders } from './hooks/useFormDataProviders';
import { useInstallationFormWorkflow } from './hooks/useInstallationFormWorkflow';
import { useSteps } from './hooks/useSteps';
import { useValuesFromRequest } from './hooks/useValuesFromRequest';
import {
  FieldSection,
  InstallationFormField,
  InstallationForm as InstallationFormType,
  type StepKey,
} from './installation.types';
import { PageActions } from './PageActions';
import { useMandateFormQuery } from './useMandateFormQuery';
import { useMeterFormQuery } from './useMeterFormQuery';
import { useOwnerFormQuery } from './useOwnerFormQuery';
import { getDefaultValues } from './utils/getDefaultValues';

const LOCALE_ERRORS_TO_REMAP: Record<string, string> = {
  ...ERRORS_TO_REMAP,
  taken: 'sales_management.form_errors.meter_taken',
  needs_meter_data: 'sales_management.form_errors.requires_a_way_to_get_meter_data',
};

export const InstallationForm = ({ user, name }: { user: User; name?: string }) => {
  /**
   * `user` is not yet defined in `useUser` hook as this component is called before the `<Page />`
   *  component that will define the `user` context
   */
  const defaultValues = getDefaultValues(user);

  const [showMeterForm, setShowMeterForm] = useState(false);
  const [globalErrorFallback, setGlobalErrorFallback] = useState<string>();
  const showMeterFormValue = useMemo(() => ({ showMeterForm, setShowMeterForm }), [showMeterForm, setShowMeterForm]);

  const form = useForm<InstallationFormType>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onSubmit',
  });

  const country = form.watch('country');
  const steps = useSteps(country);
  const stepKeys = Object.keys(steps) as StepKey[];
  const { watch, setValue } = form;
  const grids = useGrids();
  const dataProviders = useFormDataProviders(watch);

  const clearErrors = () => {
    form.clearErrors();
    setGlobalErrorFallback(undefined);
  };

  const mandates = useMandates();
  const mandateUuid = form.watch('mandate_uuid');
  // the mandate is selected after so it is always empty
  const selectedMandate = useMemo(
    () => (mandates.data || []).find((mandate) => mandate.uuid === mandateUuid),
    [mandateUuid, mandates.data],
  );

  const {
    query: installationQuery,
    createMutation: createInstallation,
    updateMutation: updateInstallation,
  } = useInstallationFormQuery(name);
  const [meterId, setMeterId] = useState(installationQuery.data?.meter?.id);

  const { createMutation: createMeter, updateMutation: updateMeter } = useMeterFormQuery({
    id: meterId,
    setMeterId: setMeterId,
  });

  const { createMutation: createOwner } = useOwnerFormQuery({
    setFormValue: form.setValue,
  });

  const { createMutation: createMandate } = useMandateFormQuery({
    uuid: selectedMandate?.uuid || null,
    setFormValue: form.setValue,
  });

  if (installationQuery.data?.has_meter && installationQuery.data.has_meter !== showMeterForm) {
    setShowMeterForm(installationQuery.data.has_meter);
  }

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'grid_id') {
        const selectedGrid = grids.data?.find(({ id }) => id === values.grid_id);
        if (dataProviders.length === 1) {
          setValue('data_provider_id', dataProviders[0].id);
        } else if (selectedGrid?.data_providers?.length === 1) {
          setValue('data_provider_id', selectedGrid.data_providers[0].id);
        } else {
          setValue('data_provider_id', null);
        }
      }

      if (name === 'via_data_provider' && values.via_data_provider === false) {
        setValue('data_provider_id', null);
        setValue('data_provider_number', '');
      }

      if (name === 'via_direct_connection' && values.via_direct_connection === false) {
        setValue('numero', '');
        setValue('meter_pass_address', null);
        setValue('equipment_direction', null);
        setValue('key', null);
        setValue('ip', null);
        setValue('tcp_port', null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, setValue, dataProviders, grids.data]);

  useEffect(() => {
    if (!meterId && installationQuery.data?.meter?.id) {
      setMeterId(installationQuery.data?.meter?.id);
    }
  }, [installationQuery.data, meterId, setMeterId]);

  const values = useValuesFromRequest(installationQuery.data);
  const { formErrors, globalError } = useErrorsFromRequest<InstallationFormType>(
    [
      installationQuery.error,
      createInstallation.error,
      updateInstallation.error,
      createMeter.error,
      updateMeter.error,
      createOwner.error,
      createMandate.error,
    ],
    user.locale,
    LOCALE_ERRORS_TO_REMAP,
  );

  const { goToNextStep, goToPreviousStep, currentStep } = useInstallationFormWorkflow(
    {
      installation: {
        mutation: (data) => {
          if (data.uuid) return updateInstallation.mutateAsync(data);

          return createInstallation.mutateAsync(data);
        },
        postMutation: (values, { isDraft }) => {
          history.pushState({}, '', edit_v2_installation_path(values.name));
          if (isDraft) return { exit: true };
        },
      },
      owner: {
        mutation: (data) => {
          if (data.owner_id === 'new_owner') return createOwner.mutateAsync(data);

          return updateInstallation.mutateAsync(data);
        },
        postMutation: (_, { isDraft }) => {
          if (isDraft) return { exit: true };
        },
      },
      meter: {
        preMutation: (values, { showMeterForm, dataProviderName }) => {
          // If this use doesn't add a meter, we exit the form
          // It will result in creating a light installation
          if (!showMeterForm) {
            return { exit: true };
          }

          if (!values.via_data_provider && !values.via_direct_connection) {
            return { error: LOCALE_ERRORS_TO_REMAP.needs_meter_data };
          }

          // We only need mandates when using a data provider
          if (values.via_direct_connection && !values.via_data_provider) {
            return { targetState: 'contact_info' };
          }

          // We handle the installation setup for most french providers by assuming they behave like Enedis
          if (isEnedisLike(values.country, dataProviderName)) return;
        },
      },
      mandate: {
        mutation: async (data, { meterId, dataProviderName }) => {
          let selectedMandate = (mandates.data || []).find((mandate) => mandate.uuid === data.mandate_uuid);
          if (!selectedMandate) selectedMandate = await createMandate.mutateAsync(data);

          if (isEnedisLike(data.country, dataProviderName)) {
            // it exits after this step and hence we need to save meter at this step
            const dataWithNewMandate = { ...data, mandate_uuid: selectedMandate.uuid };
            if (!meterId) await createMeter.mutateAsync(dataWithNewMandate);
            else await updateMeter.mutateAsync({ ...dataWithNewMandate, meterId });
          }
        },
        postMutation: (values, { dataProviderName }) => {
          // Those providers are already handled by Streem, no need to go to the next step
          if (isEnedisLike(values.country, dataProviderName)) return { exit: true };
        },
      },
      contact_info: {
        mutation: async (data) => {
          if (!meterId) await createMeter.mutateAsync(data);
          else await updateMeter.mutateAsync({ ...data, meterId });
        },
        postMutation: () => {
          return { exit: true };
        },
      },
    },
    setGlobalErrorFallback,
    clearErrors,
  );

  const handleSubmit: SubmitHandler<InstallationFormType> = async (values, event) => {
    const nativeEvent = event?.nativeEvent as React.BaseSyntheticEvent<SubmitEvent>['nativeEvent'] | undefined;
    const isDraft = nativeEvent?.submitter?.getAttribute('name') === 'draft';
    const selectedDataProvider = dataProviders.find(({ id }) => id === values.data_provider_id);
    const dataProviderName = selectedDataProvider?.name?.toLowerCase();
    await goToNextStep(values, {
      showMeterForm,
      isDraft,
      isDirty: form.formState.isDirty,
      dataProviderName,
      meterId: installationQuery.data?.meter?.id || meterId,
    });
  };

  const isLoading = [
    createInstallation.isPending,
    updateInstallation.isPending,
    installationQuery.isLoading,
    createMeter.isPending,
    updateMeter.isPending,
    createOwner.isPending,
    createMandate.isPending,
  ].some((loading) => loading);

  const isNotValidatedByOps = !!meterId && !installationQuery.data?.meter?.validated_by_ops;
  const isValidatedByOps = !!meterId && installationQuery.data?.meter?.validated_by_ops;
  const isMeterStep = currentStep === 'meter';

  const [boitier_ip, grid_id] = form.watch(['boitier_ip', 'grid_id']);
  const [via_data_provider, via_direct_connection] = form.watch(['via_data_provider', 'via_direct_connection']);

  const selectedGrid = grids.data?.find(({ id }) => id === grid_id)?.name?.toLowerCase();

  // I truly am sorry for this, this is a hack for making the progress bar work depending on what fields are shown
  const fieldSections = useMemo(
    () =>
      new Map<InstallationFormField['name'], FieldSection>(
        FIELD_SECTIONS.filter(([field, section]) => {
          if (section === 'meter') {
            if (showMeterForm) {
              if (selectedGrid === 'enedis') {
                const baseWhiteList: InstallationFormField['name'][] = ['prm'];
                const phoneMeterWhiteList: InstallationFormField['name'][] = [
                  'key',
                  'numero',
                  'aiguillage',
                  'typecompteur',
                ];

                const whiteList = boitier_ip ? baseWhiteList : [...baseWhiteList, ...phoneMeterWhiteList];

                return whiteList.includes(field);
              }

              const dataProviderFields: InstallationFormField['name'][] =
                (via_data_provider && ['data_provider_id', 'data_provider_number']) || [];
              const directConnectionFields: InstallationFormField['name'][] =
                (via_direct_connection && boitier_ip ? ['key', 'ip', 'tcp_port'] : ['key', 'numero']) || [];

              return [...dataProviderFields, ...directConnectionFields].includes(field);
            }

            return false;
          }

          return true;
        }),
      ),
    [boitier_ip, selectedGrid, showMeterForm, via_data_provider, via_direct_connection],
  );

  return (
    <mandatesContext.Provider value={mandates}>
      <showMeterFormContext.Provider value={showMeterFormValue}>
        <Form
          form={form}
          values={values}
          errors={formErrors}
          defaultValues={defaultValues}
          user={user}
          onSubmit={handleSubmit}
          steps={steps}
          commonFields={COMMON_FIELDS}
          fieldSections={fieldSections}
          backButtonHref={v2_installations_path()}
          isLoading={isLoading}
          pageProps={{
            title: name ? 'admin.installations.edit_installation' : 'admin.installations.add_installation',
            subtitle: (
              <div className="d-flex align-items-center">
                {installationQuery.data?.status === 'draft' && (
                  <Badge className="mr-2" label="common.draft" variant="danger" />
                )}
                {isNotValidatedByOps && isMeterStep && (
                  <Badge
                    className="mr-2"
                    label="admin.installations.meter_not_validated"
                    tooltip="admin.installations.meter_not_validated_tooltip"
                    variant="orange"
                  />
                )}
                {isValidatedByOps && isMeterStep && (
                  <Badge className="mr-2" label="admin.installations.meter_active" variant="primary-1" />
                )}
                {installationQuery.data?.name ?? name}
              </div>
            ),
            user: user,
            layout: 'no-background',
            isLoading: isLoading,
            error: globalError || globalErrorFallback,
            useQueryClient: false,
            pageActions: (
              <PageActions
                onPreviousStep={goToPreviousStep}
                isLoading={isLoading}
                isFirstStep={stepKeys.indexOf(currentStep) === 0}
                isLastStep={stepKeys.indexOf(currentStep) === stepKeys.length - 1}
              />
            ),
          }}
          countryKey="country"
          currentStep={currentStep}
        />
      </showMeterFormContext.Provider>
    </mandatesContext.Provider>
  );
};
