import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@GDM/Button';
import { Dialog } from '@GDM/Dialog';
import { Col, Row } from '@GDM/layout';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { useCreateMonthlyMarketResults } from '@hooks/requests/monthly-market-results/useCreateMonthlyMarketResults';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { all_players_v2_market_players_path } from '@utils/routes';
import type { CountryCode } from '@utils/types/countries';
import type { Currency } from '@utils/types/currency';
import { MonthlyMarketResultAggregator } from '@utils/types/m0';
import MarketPlayer from '@utils/types/market_player';
import { Status, PriceType, MonthlyMarketResultType } from '@utils/types/monthly_market_result';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { AggregatorsInput } from './AggregatorsInput';
import styles from './m0-aggregators.module.scss';
import { M0AggregHistory } from './M0AggregHistory';
import { M0AggregInput } from './M0AggregInput';
import { MonthInput } from './MonthInput';

export type M0AggregForm = {
  aggregator_id: string | null;
  date: Date | null;
  m0_aggreg: number | null;
};

export const M0AggregFormModal = ({
  isOpen,
  toggle,
  solar,
  aggregatorsMarketResults,
  country: selectedCountry,
  currency,
}: {
  toggle: () => void;
  isOpen: boolean;
  solar: boolean;
  aggregatorsMarketResults: MonthlyMarketResultAggregator[] | null;
  country: CountryCode;
  currency: Currency;
}) => {
  const [dialogIsOpen, setIsDialogOpen] = useState(false);
  const { create, loaded, loading, error, reset } = useCreateMonthlyMarketResults();
  const type: MonthlyMarketResultType = 'MonthlyMarketResultAggregator';
  const price_type: PriceType = solar ? 'M0_SOLAR' : 'M0_WIND';
  const market_result_status: Status = 'REAL';
  const country = selectedCountry || 'FR';
  const currencySymbol = getSymbol(currency);
  const { t } = useTranslation();

  const {
    data: aggregators,
    loading: aggregatorsLoading,
    error: aggregatorsError,
  } = useRequest<MarketPlayer[]>(all_players_v2_market_players_path({ params: { only_current: true } }), 'GET');

  // Forms
  const defaultValues: M0AggregForm = { aggregator_id: null, date: null, m0_aggreg: null };
  const form = useForm<M0AggregForm>({ defaultValues, mode: 'onChange' });
  const { watch } = form;

  const handleSubmit = (formValues: M0AggregForm) => {
    const params = {
      market_player_id: formValues.aggregator_id,
      start_date: formValues.date,
      value: formValues.m0_aggreg,
      status: market_result_status,
      type,
      price_type,
      country,
    };
    create(params);
  };

  useEffect(() => {
    if (loaded && !error) {
      window.location.reload();
    }
  }, [error, loaded]);

  const [aggregator_id, date, m0_aggreg] = watch(['aggregator_id', 'date', 'm0_aggreg']);

  const dataAlreadyExists = Boolean(
    aggregatorsMarketResults &&
      aggregatorsMarketResults.filter(
        (marketResult) =>
          marketResult.start_date == dayjs(date).format('YYYY-MM-DD') &&
          marketResult.market_player.id == aggregator_id &&
          marketResult.price_type == price_type &&
          marketResult.country == country &&
          marketResult.status == market_result_status,
      )?.length > 0,
  );

  const title = solar
    ? 'sales_management.m0_aggregators_modal_title_solar'
    : 'sales_management.m0_aggregators_modal_title_wind';

  const selectedAggregatorName = useMemo(() => {
    const aggregator = aggregators?.find((a) => a.id == aggregator_id);

    return aggregator?.short_name || aggregator?.long_name || aggregator?.company_number || '';
  }, [aggregator_id, aggregators]);

  const confirmationMessage = t('sales_management.m0_aggregators_confirm_message', {
    m0: `${m0_aggreg} ${currencySymbol}/MWh`,
    month: dayjs(date).format('MMMM YYYY'),
    aggregator: selectedAggregatorName,
    type: solar ? t('common.pv.solar') : t('common.wind.eolian'),
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        header={title}
        isLoading={loading}
        submitAction={() => {
          toggle();
          setIsDialogOpen(true);
        }}
        submitDisabled={dataAlreadyExists || !form.formState.isValid}
        data-cy="edit-m0-modal"
      >
        <FormProvider {...form}>
          <form>
            <Row>
              <Col sm={6}>
                <AggregatorsInput aggregators={aggregators} isLoading={aggregatorsLoading} error={aggregatorsError} />
              </Col>

              <Col sm={6}>
                <MonthInput />
              </Col>

              <Col sm={12}>
                <M0AggregInput currency={currency} />
              </Col>
            </Row>

            <M0AggregHistory aggregatorsMarketResults={aggregatorsMarketResults} solar={solar} currency={currency} />
          </form>
        </FormProvider>
      </Modal>

      <Dialog
        toggle={() => setIsDialogOpen(false)}
        isOpen={dialogIsOpen}
        confirmAction={form.handleSubmit(handleSubmit)}
        data-cy="confirm-m0-input"
        message={confirmationMessage}
        isLoading={loading}
        size="md"
      >
        {error?.code && (
          <Text text={error.code} className="mt-3" size="sm" icon="AlertCircle" type="danger" multiline />
        )}
        <div className={classNames('ta:right', 'mt-3', styles['edit-button'])}>
          <Button
            variant="link"
            size="sm"
            icon="Edit3"
            text="common.edit_input"
            onClick={() => {
              setIsDialogOpen(false);
              toggle();
              reset?.();
            }}
          />
        </div>
      </Dialog>
    </>
  );
};
