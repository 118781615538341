import type { CreateUpdateUnavailabilityBody } from '@api/unavailabilities';
import { formatSystemDateTime } from '@utils/formatters/formatSystemDateTime';
import { Unavailability } from '@utils/types/unavailability';
import dayjs from 'dayjs';
import { CreateModalForm } from './types';

export const convertFormToBody = (form: CreateModalForm): CreateUpdateUnavailabilityBody => {
  const body: CreateUpdateUnavailabilityBody = {
    installation_uuid: form.installation_uuid,
    start_date: formatSystemDateTime(form.start_date),
    end_date: formatSystemDateTime(form.end_date),
    capacity_value: +Number(form.capacity.value).toFixed(3),
    capacity_unit: form.capacity.unit,
    p_max: form.p_max,
    comment: form.comment,
    event_type: form.event_type,
    windmill_ids: form.windmill_ids.length ? form.windmill_ids.map((id) => +id) : null,
  };

  return body;
};

export const convertUnavailabilityToForm = (unavailability: Unavailability): CreateModalForm => {
  const body: CreateModalForm = {
    installation_uuid: unavailability.installation.uuid,
    start_date: dayjs(unavailability.start_date).toDate(),
    end_date: dayjs(unavailability.end_date).toDate(),
    capacity: {
      unit: unavailability.capacity_unit,
      value: unavailability.capacity_value,
    },
    event_type: unavailability.event_type,
    p_max: unavailability.p_max,
    comment: unavailability.comment,
    windmill_ids: unavailability.windmills?.map((windmill) => windmill.id?.toString()) || [],
    unavailability_level:
      unavailability.windmills && unavailability.windmills?.length > 0 ? 'windmill' : 'installation',
  };

  return body;
};
