import React from 'react';
import { useUser } from '@context/User.context';
import { Select, type SelectProps } from '@GDM/forms';
import { Icon } from '@GDM/Icon';
import { isCountryCode } from '@utils/filters/isCountryCode';
import { Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import classNames from 'classnames';
import styles from './country-select.module.scss';

type CountryOption = CountryCode | string | null;

type CommonProps = Omit<SelectProps<CountryOption>, 'options'> & {
  options?: SelectProps<CountryOption>['options'];
  selectedOption?: CountryOption;
  selectedOptions?: CountryOption[];
  countryFullName?: boolean;
  neverDisable?: boolean;
};

type SingleProps = CommonProps & {
  onChange: (option: Option<CountryOption> | null) => void;
  isMulti?: false;
};

type MultiProps = CommonProps & {
  onChange: (option: Option<CountryOption>[] | null) => void;
  isMulti: true;
};

export const CountrySelect = ({
  onChange,
  selectedOption,
  countryFullName = true,
  neverDisable = false,
  options,
  isMulti,
  ...restProps
}: SingleProps | MultiProps) => {
  const user = useUser();

  const countryOptions: Option<CountryOption>[] = options
    ? options
    : user.geolocation
        .filter(Boolean)
        .map((country) => ({ label: countryFullName ? `countries.${country}` : country, value: country }));

  const isMultiProps = isMulti ? { isMulti: true as const } : {};

  return (
    <Select
      {...restProps}
      options={countryOptions}
      selectedOption={selectedOption}
      isDisabled={!neverDisable && (countryOptions.length === 1 || restProps.isDisabled)}
      isCountry
      onChange={
        onChange as unknown as ((options: Option<string | null>[] | Option<string | null> | null) => void) | undefined
      }
      {...isMultiProps}
      formatOptionLabel={(data) => (
        <div className={styles.container}>
          <Icon name={isCountryCode(data.value) ? data.value : 'XX'} size={12} className="mr-1" />
          <span className={classNames('ml-2', styles.label)}>{data.label}</span>
        </div>
      )}
    />
  );
};
