import React from 'react';
import { Restricted } from '@components/Restricted';
import { Col, Row } from '@GDM/layout';
import useM0Aggreg from '@hooks/m0s/useM0Aggreg';
import useM0ByType from '@hooks/m0s/useM0ByType';
import type { CountryCode, Timezone } from '@utils/types/countries';
import type { Currency } from '@utils/types/currency';
import { M0Aggreg } from './M0Aggreg';

export const M0Aggregators = ({
  country,
  currency,
  timezone,
}: {
  country: CountryCode | null;
  currency: Currency;
  timezone: Timezone;
}) => {
  const { rawM0s, solarData, windData, loading } = useM0Aggreg(country);
  const { wind, solar, loadingWind, loadingSolar } = useM0ByType(country);

  return (
    <Restricted permissions={['display:view_salesfocus']}>
      <Row className="mt-3">
        <Col>
          <M0Aggreg
            data={solarData}
            base={solar}
            type="solar"
            aggregatorsMarketResults={rawM0s}
            loading={loadingSolar || loading}
            currency={currency}
            timezone={timezone}
            country={country}
          />
        </Col>
        <Col>
          <M0Aggreg
            data={windData}
            base={wind}
            type="wind"
            aggregatorsMarketResults={rawM0s}
            loading={loadingWind || loading}
            currency={currency}
            timezone={timezone}
            country={country}
          />
        </Col>
      </Row>
    </Restricted>
  );
};
