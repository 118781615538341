import React from 'react';
import { FilterContainer } from '@GDM/Filters';
import { CountrySelect } from '@GDM/forms';
import { isCountryCode } from '@utils/filters/isCountryCode';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { usePortfolio } from '../usePortfolio';
import { useFilters } from './useFilters';

export const CountryFilters = () => {
  const { data } = usePortfolio();
  const { countries, setCountries } = useFilters();

  const allCountries = [...new Set((data || []).map((dd) => dd.installation_country).filter((country) => country))];
  const options = allCountries
    .map((country) => ({ label: `countries.${country}`, value: country }))
    .sort(sortOptionsByLabelAsc);

  return (
    <FilterContainer size="fit">
      <CountrySelect
        options={options}
        onChange={(options) => {
          const selectedCountries = options?.map((option) => option.value) || [];
          const validCountries = selectedCountries.filter(isCountryCode);

          setCountries?.(validCountries);
        }}
        selectedOptions={countries}
        placeholder="common.country"
        classNamePrefix="country-picker"
        isClearable
        isMulti
      />
    </FilterContainer>
  );
};
