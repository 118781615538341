import React from 'react';
import { Restricted } from '@components/Restricted';
import { AltContent } from '@GDM/AltContent';
import { Card } from '@GDM/Card';
import { Spinner } from '@GDM/Spinner';
import { useRequest } from '@hooks/useRequest';
import { formatSystemDate } from '@utils/formatters';
import { v2_market_prices_path } from '@utils/routes';
import type { CountryCode, Timezone } from '@utils/types/countries';
import { Currency } from '@utils/types/currency';
import { AggregatedPriceData } from '@utils/types/price';
import type { NationalPrices } from '../national-prices.types';
import { ImbalancePositiveChart } from './ImbalancePositiveChart';

export const ImbalancePositive = ({
  country,
  timezone,
  currency,
  data,
}: {
  country: CountryCode | null;
  timezone: Timezone;
  currency: Currency;
  data?: NationalPrices;
}) => {
  const rawStartDate = data?.base?.[0]?.[0] || data?.solar?.[0]?.[0] || data?.wind?.[0]?.[0];
  const startDate: string | null = rawStartDate ? formatSystemDate(rawStartDate) : null;

  const rawEndDate =
    data?.base?.[data.base.length - 1]?.[0] ||
    data?.solar?.[data.solar.length - 1]?.[0] ||
    data?.wind?.[data.wind.length - 1]?.[0];

  const endDate: string | null = rawEndDate ? formatSystemDate(rawEndDate) : null;

  const { data: positiveImbalance, loading: positiveImbalanceLoading } = useRequest<{
    currency: Currency;
    values: AggregatedPriceData[];
  }>(
    country && startDate && endDate
      ? v2_market_prices_path({ country, type: 'positive_imbalance', start_date: startDate, end_date: endDate })
      : null,
    'GET',
  );
  const { data: spot, loading: spotLoading } = useRequest<{ currency: Currency; values: AggregatedPriceData[] }>(
    country && startDate && endDate
      ? v2_market_prices_path({ country, type: 'spot', start_date: startDate, end_date: endDate })
      : null,
    'GET',
  );
  const loading = positiveImbalanceLoading || spotLoading;

  if (!country || !startDate || !endDate) return null;

  return (
    <Restricted permissions={['display:view_salesfocus']}>
      <Card className="mt-3" title="sales_management.imbalance">
        {loading && <Spinner />}
        {positiveImbalance && spot && !loading && (
          <div className="w-100">
            <ImbalancePositiveChart
              imbalance={positiveImbalance.values ?? []}
              spot={spot.values ?? []}
              timezone={timezone}
              currency={currency}
            />
          </div>
        )}
        {(!positiveImbalance || !spot) && !loading && <AltContent />}
      </Card>
    </Restricted>
  );
};
