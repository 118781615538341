import React from 'react';
import { useUser } from '@context/User.context';
import { Alert } from '@GDM/Alert';
import { Col, Row } from '@GDM/layout';
import useTranslation from '@hooks/useTranslation';
import { parseM0Array } from '@utils/NationalPrices/parseM0Array';
import { getTimezone, getUserTimezone } from '@utils/timezones';
import type { CountryCode } from '@utils/types/countries';
import { CapturePriceChart } from './CapturePriceChart';
import { HistoricM0Chart } from './HistoricM0Chart';
import { ImbalancePositive } from './ImbalancePositive';
import { M0Aggregators } from './M0Aggregators';
import { M0Card } from './M0Card';
import styles from './national-prices.module.scss';
import { useM0Data } from './utils/useM0Data';

export const NationalPricesContent = ({ country }: { country: CountryCode | null }) => {
  const user = useUser();
  const { t } = useTranslation();
  const { data, isPending, error } = useM0Data(country);
  const solarM0 = parseM0Array(data?.solar[data?.solar.length - 1]);
  const windM0 = parseM0Array(data?.wind[data?.wind.length - 1]);
  const baseloadM0 = parseM0Array(data?.base[data?.base.length - 1]);
  const currency = data?.currency ?? 'EUR';
  const timezone = country ? getTimezone(country) : getUserTimezone(user);

  return (
    <>
      {error && <Alert variant="danger" label={error?.code ? t(error.code, { country: country }) : null} />}

      <div className={styles['card-container']}>
        {country === 'FR' && solarM0 && (
          <div className={styles.card}>
            <M0Card currency={currency} isLoading={isPending} m0={solarM0} type="solar" />
          </div>
        )}
        {country === 'FR' && windM0 && (
          <div className={styles.card}>
            <M0Card currency={currency} isLoading={isPending} m0={windM0} type="wind" />
          </div>
        )}
        {baseloadM0 && (
          <div className={styles.card}>
            <M0Card
              currency={currency}
              isLoading={isPending}
              m0={baseloadM0}
              type={country === 'FR' ? 'm0_base' : 'base'}
            />
          </div>
        )}
      </div>
      <HistoricM0Chart country={country} data={data || null} currency={currency} isLoading={isPending} />
      {country === 'FR' && data && (
        <Row>
          {data?.solar && (
            <Col>
              <CapturePriceChart data={data.solar} captureFactor={data.solar_capture_factor} type="solar" />
            </Col>
          )}
          {data?.wind && (
            <Col>
              <CapturePriceChart data={data.wind} captureFactor={data.wind_capture_factor} type="wind" />
            </Col>
          )}
        </Row>
      )}
      {country === 'FR' && data && <M0Aggregators country={country} currency={currency} timezone={timezone} />}
      {country !== 'IT' && <ImbalancePositive country={country} timezone={timezone} currency={currency} data={data} />}
    </>
  );
};
