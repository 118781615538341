import React from 'react';
import type { IconNames } from '@GDM/Icon';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import type { CellContext } from '@tanstack/react-table';
import type { Unavailability } from '@utils/types/unavailability';

export const SharingCell = ({ row: { original } }: React.PropsWithChildren<CellContext<Unavailability, unknown>>) => {
  const { t } = useTranslation();

  const statusMap: {
    [status in Unavailability['sharing_status']]?: {
      icon: IconNames;
      label: string;
      variant: string;
    };
  } = {
    failed: {
      icon: 'X',
      variant: 'text-red',
      label: t('monitoring.unavailabilities.sharing_statuses.failed'),
    },

    received: {
      icon: 'Check',
      label: t('monitoring.unavailabilities.sharing_statuses.received'),
      variant: 'text-blue',
    },
  };

  if (!statusMap[original.sharing_status]) return null;

  const { icon, variant, label } = statusMap[original.sharing_status] || {};

  return <Text icon={icon} iconProps={{ className: variant }} text={label} />;
};
