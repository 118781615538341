import { useMemo } from 'react';
import { SERVICE_STATUS_COLUMNS } from '@pages/ServiceStatus/Table/ColumnList';
import { ColumnDef } from '@tanstack/react-table';
import { ServiceStatus } from '@utils/types/service-status';

export function useColumns() {
  const columns = useMemo<ColumnDef<ServiceStatus>[]>(() => {
    return [
      SERVICE_STATUS_COLUMNS.NAME,
      SERVICE_STATUS_COLUMNS.UP,
      SERVICE_STATUS_COLUMNS.ERROR_NUMBER,
      SERVICE_STATUS_COLUMNS.LAST_STATUS_AT,
      SERVICE_STATUS_COLUMNS.MANUAL_FIX,
    ];
  }, []);

  return { columns };
}
