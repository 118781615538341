import React, { useMemo } from 'react';
import ServiceStatus from '@pages/ServiceStatus/ServiceStatus';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { User } from '@utils/types/user';

interface ServiceStatusWithQueryProps {
  user: User;
}

export const ServiceStatusWithQuery = ({ user }: ServiceStatusWithQueryProps) => {
  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ServiceStatus user={user} />
    </QueryClientProvider>
  );
};

export default ServiceStatusWithQuery;
