import React, { useMemo } from 'react';
import { GroupedSelect } from '@GDM/forms';
import { FormattedOptionEnergy } from '@GDM/forms/Select/CustomComponents/OptionEnergy/FormattedOptionEnergy';
import OptionEnergy from '@GDM/forms/Select/CustomComponents/OptionEnergy/OptionEnergy';
import useBooks from '@hooks/requests/useBooks';
import { useInstallationsLegacy } from '@hooks/requests/useInstallations';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { EnergyType, GroupedOption, Option } from '@utils/types/common-types';
import { Controller, type ControllerRenderProps, Control } from 'react-hook-form';
import type { NotificationFormData } from './NotificationFormModal';

export type EnergyOption = Option<string> & { energy?: EnergyType };

export const InstallationsBooksSelect = ({
  control,
  onChange,
  disabled,
}: {
  disabled: boolean;
  control: Control<NotificationFormData>;
  onChange: (field: ControllerRenderProps<NotificationFormData, 'internal_name'>, option: EnergyOption | null) => void;
}) => {
  const { data: books, isLoading: booksLoading } = useBooks();
  const { data: installations, loading: installationsLoading } = useInstallationsLegacy({ type: 'operational' });

  const options = useMemo(() => {
    const internalOptions: GroupedOption<string, EnergyOption>[] = [
      {
        label: 'common.books',
        options:
          books
            ?.map(({ uuid, name }) => ({ value: uuid, label: name, energy: 'book' as EnergyType }))
            .sort(sortOptionsByLabelAsc) || [],
      },
      {
        label: 'common.installations',
        options:
          installations
            ?.map(({ name, energy }) => ({ value: name, label: name, energy }))
            .sort(sortOptionsByLabelAsc) || [],
      },
    ];

    return internalOptions;
  }, [books, installations]);

  return (
    <Controller
      control={control}
      name="internal_name"
      rules={{ required: !disabled }}
      render={({ field, fieldState }) => (
        <GroupedSelect
          isDisabled={disabled}
          options={options}
          isLoading={booksLoading || installationsLoading}
          isMulti={false}
          value={getSelectedOption(options, field.value)}
          onChange={(option) => onChange(field, option)}
          components={{ Option: OptionEnergy }}
          formatOptionLabel={FormattedOptionEnergy}
          label="admin.alerts.scope"
          isClearable
          size="lg"
          hasError={Boolean(fieldState.error)}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  );
};

/**
 * This method is used to sync the option from two sources (internal state and form state)
 */
const getSelectedOption = (options: GroupedOption<string, EnergyOption>[], selectedOptionValue: string | null) => {
  if (!selectedOptionValue) return null;

  for (const group of options) {
    const selectedOption = group.options.find((option) => option.value === selectedOptionValue);
    if (selectedOption) return selectedOption;
  }

  return null;
};
