import type { Option } from '@utils/types/common-types';
import type { CapacityUnit, EventType } from '@utils/types/unavailability';

export const EVENT_TYPES_OPTIONS: Option<EventType>[] = [
  { label: 'monitoring.unavailabilities.maintenance', value: 'maintenance' },
  { label: 'monitoring.unavailabilities.maintenance_producer', value: 'maintenance_producer' },
  { label: 'monitoring.unavailabilities.maintenance_offtaker', value: 'maintenance_offtaker' },
  { label: 'monitoring.unavailabilities.maintenance_manufacturer', value: 'maintenance_manufacturer' },
  { label: 'monitoring.unavailabilities.grid', value: 'grid' },
  { label: 'monitoring.unavailabilities.failure', value: 'failure' },
  { label: 'monitoring.unavailabilities.failure_producer', value: 'failure_producer' },
  { label: 'monitoring.unavailabilities.failure_offtaker', value: 'failure_offtaker' },
  { label: 'monitoring.unavailabilities.other', value: 'other' },
  { label: 'monitoring.unavailabilities.major_force', value: 'major_force' },
];

export const CAPACITY_UNIT_OPTIONS: Option<CapacityUnit>[] = [
  { label: 'MW', value: 'MW' },
  { label: '%', value: 'percentage' },
];
