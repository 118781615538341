import React from 'react';

import { useInstallationsLegacy } from '@hooks/requests/useInstallations';
import Page from '@pages/Page';
import type { User } from '@utils/types/user';
import { NegativePrices } from './NegativePrices';

const NegativePricesPage = ({ user }: { user: User }) => {
  const { data: installations, loading } = useInstallationsLegacy();

  const isSolar = installations?.some((installation) => installation.energy === 'solar') || false;
  const hasFrenchInstallation = installations?.some((installation) => installation.country === 'FR') || false;

  return (
    <Page title="common.negative_prices" user={user} layout="no-background" isLoading={loading}>
      <NegativePrices
        wrapChart
        hasMultipleInstallations
        hasFrenchInstallation={hasFrenchInstallation}
        isSolar={isSolar}
      />
    </Page>
  );
};

export default NegativePricesPage;
