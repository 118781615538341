import React, { useState } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { getPaginationRowModel, getSortedRowModel, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { ServiceStatus } from '@utils/types/service-status';
import { useColumns } from './useColumns';

interface ServiceStatusTableProps {
  service_status: ServiceStatus[];
}

export const ServiceStatusTable = ({ service_status }: ServiceStatusTableProps) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });

  const { columns } = useColumns();

  const table = useReactTable({
    columns,
    data: service_status,
    sortDescFirst: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { sorting: [{ id: 'name', desc: false }] },
  });

  const pageSize = pagination.pageSize;
  const pageCount = table.getPageCount();

  return (
    <div>
      <Table data-cy="service-status-table">
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      {service_status.length > pageSize && (
        <TableActions className="p-0">
          <TablePageSizeSelect
            pageSize={pageSize}
            setPageSize={table.setPageSize}
            totalEntries={service_status.length}
          />
          <TablePagination pageCount={pageCount} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </div>
  );
};
