import React, { useCallback, useEffect } from 'react';
import ControlledDatePicker from '@components/FormInputs/ControlledDatePicker';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { ControlledToggle } from '@components/FormInputs/ControlledToggle';
import { Filters, FilterContainer, useDynamicOptions } from '@GDM/Filters';
import useBooks from '@hooks/requests/useBooks';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { EnergyType, Option } from '@utils/types/common-types';
import type { Unavailability, UnavailabilityStatus } from '@utils/types/unavailability';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import type { UseFormReturn } from 'react-hook-form';
import type { UnavailabilitiesFiltersType } from './useFilters';

export const UnavailabilityFilters = ({
  filtersForm,
  filteredUnavailabilities,
  unavailabilities,
}: {
  filtersForm: UseFormReturn<UnavailabilitiesFiltersType>;
  filteredUnavailabilities: Unavailability[];
  unavailabilities: Unavailability[];
}) => {
  const { control, watch, setValue, reset } = filtersForm;

  const { data } = useBooks();

  const getBookOptions = useCallback(
    (filteredUnavailabilities: Unavailability[]) => {
      const books = data || [];
      const allInstallationNames = filteredUnavailabilities
        ? filteredUnavailabilities.map((unavailability) => unavailability.installation.name)
        : [];

      const relevantBooks = books
        ? books.filter((book) => intersection(book.installation_names, allInstallationNames).length > 0)
        : [];

      return relevantBooks
        .map((book) => ({
          label: book.name,
          value: book.uuid,
          energy: 'book',
        }))
        .sort(sortOptionsByLabelAsc);
    },
    [data],
  );

  const bookOptions = useDynamicOptions(getBookOptions, 'bookUuid', filteredUnavailabilities, unavailabilities);

  const installationOptions = useDynamicOptions(
    getInstallationOptions,
    'installationName',
    filteredUnavailabilities,
    unavailabilities,
  );
  const eventOptions = useDynamicOptions(getEventOptions, 'event', filteredUnavailabilities, unavailabilities);
  const statusOptions = useDynamicOptions(getStatusOptions, 'status', filteredUnavailabilities, unavailabilities);
  const sourceOptions = useDynamicOptions(getSourceOptions, 'source', filteredUnavailabilities, unavailabilities);
  const windmillOptions = useDynamicOptions(
    getWindmillsOptions,
    'windmill_ids',
    filteredUnavailabilities,
    unavailabilities,
  );

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name && name !== 'last_selected_filter' && type === 'change') {
        setValue('last_selected_filter', name);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <Filters form={filtersForm} className="p-0" onClear={() => reset()}>
      <FilterContainer size="datepicker">
        <ControlledDatePicker control={control} name="dateRange" selectsRange />
      </FilterContainer>

      <FilterContainer>
        <ControlledSelect
          control={control}
          name="installationName"
          placeholder="common.filters.installation_name"
          options={installationOptions}
          isMulti
          inline
          full
          isInstallationOrBook
        />
      </FilterContainer>

      <FilterContainer>
        <ControlledSelect
          control={control}
          name="bookUuid"
          options={bookOptions}
          placeholder="admin.books.book_name"
          isClearable
          isInstallationOrBook
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <ControlledSelect
          control={control}
          name="windmill_ids"
          options={windmillOptions}
          placeholder="common.wind.turbine"
          isMulti
          inline
          isClearable
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <ControlledRadioButtons
          control={control}
          name="energy"
          options={[
            { label: 'common.all', value: 'all' },
            { label: 'common.wind.eolian', value: 'wind' },
            { label: 'common.pv.solar', value: 'solar' },
            { label: 'common.hydro.short', value: 'hydro' },
          ]}
        />
      </FilterContainer>

      <FilterContainer>
        <ControlledSelect
          control={control}
          name="event"
          options={eventOptions}
          placeholder="monitoring.unavailabilities.event_type"
          isMulti
          inline
          isClearable
        />
      </FilterContainer>

      <FilterContainer>
        <ControlledSelect
          control={control}
          name="status"
          options={statusOptions}
          placeholder="common.status_label"
          isMulti
          inline
          isClearable
        />
      </FilterContainer>

      <FilterContainer>
        <ControlledSelect
          control={control}
          name="sharing_status"
          options={[
            { label: 'monitoring.unavailabilities.sharing_statuses.received', value: 'received' },
            { label: 'monitoring.unavailabilities.sharing_statuses.failed', value: 'failed' },
          ]}
          placeholder="monitoring.unavailabilities.sharing_status"
          isClearable
        />
      </FilterContainer>

      <FilterContainer>
        <ControlledSelect
          control={control}
          name="source"
          options={sourceOptions}
          placeholder="monitoring.invoicing.source"
          isMulti
          inline
          isClearable
        />
      </FilterContainer>

      <FilterContainer>
        <ControlledToggle control={control} icon="Zap" label="monitoring.unavailabilities.chart" name="chartVisible" />
      </FilterContainer>
    </Filters>
  );
};

const getInstallationOptions = (unavailabilities: Unavailability[]) => {
  if (!unavailabilities.length) {
    return [];
  }

  const installations = new Map<string, Option<string> & { energy: EnergyType }>();

  unavailabilities.forEach((unavailability) => {
    installations.set(unavailability.installation.name, {
      label: unavailability.installation.name,
      value: unavailability.installation.name,
      energy: unavailability.installation.energy,
    });
  });

  return Array.from(installations.values()).sort(sortOptionsByLabelAsc);
};

const getEventOptions = (filteredUnavailabilities: Unavailability[]) => {
  return [
    ...uniqBy(filteredUnavailabilities, 'event_type').map((unavailability) => ({
      label: `monitoring.unavailabilities.${unavailability.event_type}`,
      value: unavailability.event_type,
    })),
  ];
};

const getStatusOptions = (filteredUnavailabilities: Unavailability[]) => {
  const existingStatus: UnavailabilityStatus[] = uniq(
    filteredUnavailabilities.map((filteredUnavailabilities) => filteredUnavailabilities.status),
  );
  const allStatus: UnavailabilityStatus[] = ['planned', 'ongoing', 'done', 'cancelled'];

  return [
    ...intersection(allStatus, existingStatus).map((status) => ({
      label: `monitoring.unavailabilities.status.${status}`,
      value: status,
    })),
  ];
};

const getSourceOptions = (filteredUnavailabilities: Unavailability[]) => {
  return [
    ...uniqBy(filteredUnavailabilities, 'source').map((unavailability) => ({
      label: `monitoring.unavailabilities.sources.${unavailability.source}`,
      value: unavailability.source,
    })),
  ];
};

const getWindmillsOptions = (unavailabilities: Unavailability[]): Option<string>[] => {
  const windmills = new Map<number, Option<string>>();

  unavailabilities.forEach((unavailability) => {
    if (unavailability.windmills) {
      unavailability.windmills.forEach((windmill) => {
        windmills.set(windmill.id, { label: windmill.name || windmill.turbine.name, value: windmill.id?.toString() });
      });
    }
  });

  return Array.from(windmills.values()).sort(sortOptionsByLabelAsc);
};
