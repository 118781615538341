import safeSumReducer from '@utils/safeSumReducer';
import { Installation, InstallationWithMeterInfo } from '@utils/types/installation';
import { Unavailability } from '@utils/types/unavailability';
import dayjs from 'dayjs';

export const computeAvailabilities = (
  unavailabilities: Unavailability[],
  total_p_max: number,
  start_date: Date | null,
  end_date: Date | null,
): [number, number][] => {
  if (!start_date || !end_date) return [];

  const startDate = dayjs(start_date).startOf('day').valueOf();
  const endDate = dayjs(end_date).valueOf();

  const availabilitiesMap = generateBaseAvailabilitiesRange(startDate, endDate, total_p_max);

  unavailabilities.forEach((unavailability) => {
    if (!unavailability.real_capacity_value) return;

    const unavailabilityStartDate = dayjs(unavailability.start_date).startOf('hour').valueOf();
    const unavailabilityEndDate = dayjs(unavailability.end_date).valueOf();

    let start = Math.max(unavailabilityStartDate, startDate);
    const end = Math.min(unavailabilityEndDate, endDate);

    while (start < end) {
      if (availabilitiesMap.has(start)) {
        const currentAvailability = availabilitiesMap.get(start) || 0;

        availabilitiesMap.set(start, currentAvailability - unavailability.real_capacity_value);
      }

      start += 60 * 60 * 1000;
    }
  });

  return Array.from(availabilitiesMap);
};

const generateBaseAvailabilitiesRange = (startDate: number, endDate: number, pMax: number) => {
  const availabilitiesMap = new Map<number, number>();

  while (endDate && startDate <= endDate) {
    availabilitiesMap.set(startDate, pMax);

    startDate += 60 * 60 * 1000;
  }

  return availabilitiesMap;
};

export const computeTotalPmax = (installations: Array<Installation | InstallationWithMeterInfo>): number =>
  installations
    ? installations
        .map((installation) => ('p_max' in installation ? installation.p_max || 0 : 0))
        .reduce(safeSumReducer, 0) / 1000 // to convert to MW
    : 0;
