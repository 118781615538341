import React from 'react';
import { Button } from '@GDM/Button';
import { useManualFixServiceStatus } from '@hooks/requests/useServiceStatus';
import { CellContext } from '@tanstack/react-table';
import { ServiceStatus } from '@utils/types/service-status';

export const ManualFix = ({ row: { original } }: CellContext<ServiceStatus, unknown>) => {
  const { mutate: manualFix, isPending } = useManualFixServiceStatus();

  return (
    <Button
      icon="CheckCircle"
      variant="primary-2"
      size="sm"
      onClick={() => manualFix(original.id)}
      disabled={isPending}
    >
      {isPending ? 'Fixing...' : 'Fix'}
    </Button>
  );
};
