import React from 'react';
import Page from '@pages/Page';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from '@utils/types/user';
import { UnavailabilitiesPageContent } from './UnavailabilitiesPageContent';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Unavailabilities = (props: { user: User }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Page
        user={props.user}
        locale={props.user.locale}
        title="common.unavailabilities"
        useQueryClient={false}
        layout="no-background"
      >
        <UnavailabilitiesPageContent />
      </Page>
    </QueryClientProvider>
  );
};

export default Unavailabilities;
