import React from 'react';
import { Modal } from '@GDM/Modal';
import { useUnavailabilityCancelMutation } from '@hooks/requests/useUnavailabilities';
import useTranslation from '@hooks/useTranslation';
import { Unavailability } from '@utils/types/unavailability';
import type { ToggleModal } from './UnavailabilitiesPageContent';

export const CancelModal = ({
  toggle,
  isOpen,
  selectedUnavailability,
  startDate,
  endDate,
}: {
  toggle: ToggleModal;
  isOpen: boolean;
  selectedUnavailability: Unavailability | null;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { t } = useTranslation();
  const cancelMutation = useUnavailabilityCancelMutation({ start_date: startDate, end_date: endDate });

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(null)}
      header="monitoring.unavailabilities.cancel_modal.header"
      submitAction={
        selectedUnavailability
          ? () => cancelMutation.mutate(selectedUnavailability?.uuid, { onSuccess: () => toggle(null) })
          : undefined
      }
      isLoading={cancelMutation.isPending}
      error={cancelMutation.error?.message}
    >
      {t('monitoring.unavailabilities.cancel_modal.body')}
    </Modal>
  );
};
