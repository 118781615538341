import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInstallationsLegacy } from '@hooks/requests/useInstallations';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { InstallationWithMeterInfo as Installation } from '@utils/types/installation';
import { User } from '@utils/types/user';
import { installationsPageContext, InstallationsPageContext } from './context/installations-page.context';
import styles from './installations.module.scss';
import { InstallationsFilters, InstallationFiltersType } from './InstallationsFilters';
import { InstallationsTab } from './tabs/InstallationsTab';

const InstallationsLightPage = ({ user }: { user: User }) => {
  const { t } = useTranslation(user.locale);

  const [filters, setFilters] = useState<InstallationFiltersType>({
    name: '',
    type: 'all',
    status: null,
    country: null,
  });

  const installationsRequest = useInstallationsLegacy({ type: 'operational_with_draft', withMeterInfo: true });
  const [installations, setInstallations] = useState<Installation[]>([]);
  const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null);
  const [filteredInstallations, setFilteredInstallations] = useState<Installation[]>([]);

  const applyFilter = useCallback((filters: InstallationFiltersType, installations: Installation[]) => {
    let _filteredInstallations: Installation[] = installations;
    if (filters.name) {
      _filteredInstallations = matchSorterInstanceStrict(installations || [], filters.name, {
        keys: ['name', 'prm'],
      });
    }

    if (filters.country) {
      _filteredInstallations = _filteredInstallations.filter(
        (installation) => installation?.country === filters.country,
      );
    }

    if (filters.type !== 'all') {
      _filteredInstallations = _filteredInstallations.filter((installation) => installation?.energy === filters.type);
    }

    if (filters.status) {
      _filteredInstallations = _filteredInstallations.filter((installation) => installation?.status === filters.status);
    }

    setFilteredInstallations(_filteredInstallations);
  }, []);

  useEffect(() => {
    applyFilter(filters, installations);
  }, [filters, installations, applyFilter]);

  useEffect(() => {
    if (installationsRequest.loaded) {
      if (installationsRequest.data) {
        setInstallations(installationsRequest.data);
        applyFilter(filters, installationsRequest.data);
      } else {
        setInstallations([]);
        setFilteredInstallations([]);
      }
    }
  }, [installationsRequest.data, installationsRequest.loaded, filters, applyFilter]);

  const contextValue = useMemo<InstallationsPageContext>(() => {
    const value: InstallationsPageContext = {
      loading: installationsRequest.loading,
      filters,
      setFilters,
      installations,
      selectedInstallation,
      setSelectedInstallation,
      filteredInstallations,
      setFilteredInstallations,
    };

    return value;
  }, [installations, selectedInstallation, filteredInstallations, installationsRequest.loading, filters]);

  return (
    <Page title={t('common.installations')} user={user} isLoading={installationsRequest.loading} layout="no-background">
      <installationsPageContext.Provider value={contextValue}>
        <InstallationsFilters className="p-0" isLight />
        <div className={styles['tables-container']}>
          <InstallationsTab isLight />
        </div>
      </installationsPageContext.Provider>
    </Page>
  );
};

export default InstallationsLightPage;
