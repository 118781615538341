import { useCallback, useContext, useMemo, useState } from 'react';
import { isValidDate } from '@utils/isValidDate';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { DashboardViewAllData, DataType, TypeFilter } from '../portfolio.definitions';
import { filtersContext, FiltersContext } from './filters.context';

export const useFilters = (): FiltersContext => useContext(filtersContext);

export const useFilteredPortfolio = (data: DashboardViewAllData[] | undefined, dataType: DataType) => {
  const [type, setType] = useState<TypeFilter>('all');
  const [countries, setCountries] = useState<FiltersContext['countries']>([]);
  const [installationName, setInstallationName] = useState<string>('');
  const [clientId, setClientId] = useState<string>('');
  const [commissioningDates, setCommissioningDates] = useState<[string, string] | null>(null);

  const [bookName, setBookName] = useState<string>('');
  const [events, setEvents] = useState<FiltersContext['events']>([]);
  const [contractTypes, setContractTypes] = useState<FiltersContext['contractTypes']>([]);

  const puissanceCreteMax = useMemo(
    () => (data && data?.length > 0 ? Math.ceil(Math.max(...data.map((d) => d.puissancecrete || 0))) : 0),
    [data],
  );
  const energyMax = useMemo(
    () => (data && data?.length > 0 ? Math.ceil(Math.max(...data.map((d) => Number(d.sumdata) || 0))) : 0),
    [data],
  );

  const [filters, setFilters] = useState<FiltersContext['filters']>({
    puiCrete: [0, puissanceCreteMax],
    energy: [0, energyMax],
    capacityFactor: [0, 100],
    coverage: [0, 100],
  });

  const filterContext = useMemo<FiltersContext>(() => {
    const context: FiltersContext = {
      type,
      setType,
      countries,
      setCountries,
      installationName,
      setInstallationName,
      commissioningDates,
      setCommissioningDates,
      clientId,
      setClientId,
      bookName,
      setBookName,
      filters,
      setFilters,
      events,
      setEvents,
      contractTypes,
      setContractTypes,
    };

    return context;
  }, [type, countries, installationName, commissioningDates, clientId, bookName, filters, events, contractTypes]);

  const filterData = useCallback(
    (d: DashboardViewAllData) => {
      if (type && type !== 'all' && d.type !== type) return false;

      return true;
    },
    [type],
  );

  const filteredPortfolio = useMemo(() => {
    const startDate = commissioningDates && new Date(commissioningDates[0]);
    const endDate = commissioningDates && new Date(commissioningDates[1]);

    let portfolio = dataType === 'books' ? data?.filter(({ book }) => book) : data?.filter(({ book }) => !book);

    portfolio ||= [];

    if (installationName !== '' || (bookName !== '' && dataType === 'books')) {
      portfolio = matchSorterInstanceStrict(portfolio, installationName || bookName, { keys: ['ref_centrale'] });
    }

    if (dataType === 'installations' && bookName !== '') {
      portfolio = matchSorterInstanceStrict(portfolio, bookName, { keys: ['books'] });
    }

    if (dataType === 'installations' && clientId !== '') {
      portfolio = matchSorterInstanceStrict(portfolio, clientId, { keys: ['id_client'] });
    }

    if (dataType === 'installations' && countries.length > 0) {
      portfolio = portfolio.filter((dashboardData) =>
        countries.some((country) => {
          // Cannot use includes because contract_type can either be null or undefined
          if (country === null && !dashboardData.installation_country) {
            return !dashboardData.installation_country;
          }

          return dashboardData.installation_country === country;
        }),
      );
    }

    if (dataType === 'installations' && contractTypes.length > 0) {
      portfolio = portfolio.filter((dashboardData) =>
        contractTypes.some((contractType) => {
          // Cannot use includes because contract_type can either be null or undefined
          if (contractType === null && !dashboardData.contract_type) {
            return !dashboardData.contract_type;
          }

          return dashboardData.contract_type === contractType;
        }),
      );
    }

    if (commissioningDates) {
      portfolio = portfolio.filter((d) => {
        const itemDate = new Date(d.commissioning_date);

        return (
          !isValidDate(startDate) ||
          !isValidDate(endDate) ||
          (startDate && endDate && itemDate > startDate && itemDate < endDate)
        );
      });
    }

    if (filters) {
      portfolio = portfolio?.filter((d) => {
        const puissanceCrete = d.puissancecrete;
        const energy = Number(d.sumdata) || 0;
        const capacityFactor = d.capacity_factor;
        const coverage = d.coverage;

        const condition =
          (puissanceCrete || 0) >= filters.puiCrete[0] &&
          (puissanceCrete || 0) <= filters.puiCrete[1] &&
          energy >= filters.energy[0] &&
          energy <= filters.energy[1] &&
          Number(capacityFactor) >= filters.capacityFactor[0] &&
          Number(capacityFactor) <= filters.capacityFactor[1] &&
          Number(coverage) >= filters.coverage[0] &&
          Number(coverage) <= filters.coverage[1];

        return condition;
      });
    }

    if (events?.length) {
      portfolio = portfolio?.filter((d) => {
        return d.event_types?.some((event_type) => events.includes(event_type));
      });
    }

    return portfolio.filter(filterData);
  }, [
    bookName,
    clientId,
    commissioningDates,
    contractTypes,
    countries,
    data,
    dataType,
    events,
    filterData,
    filters,
    installationName,
  ]);

  return {
    filterContext,
    filteredPortfolio,
  };
};
