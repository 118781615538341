import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { RiskAnalysisConfiguration } from './Configuration';
import { RiskAnalysisReport } from './Report/Report';
import { RiskAnalysisFormFilters, RiskAnalysisReportType } from './types';

export const RiskAnalysisDataViz = ({
  form,
  type,
}: {
  form: UseFormReturn<RiskAnalysisFormFilters>;
  type: RiskAnalysisReportType;
}) => {
  const [areFiltersReady, setFilterReadiness] = useState(true);

  return (
    <>
      <RiskAnalysisConfiguration
        form={form}
        setFilterReadiness={setFilterReadiness}
        areFiltersReady={areFiltersReady}
      />
      <RiskAnalysisReport form={form} type={type} areFiltersReady={areFiltersReady} />
    </>
  );
};
