import type { RequestError, RequestErrorBody } from '@hooks/useRequest';
import type { NationalPrices } from '@pages/NationalPrices/national-prices.types';
import fetchWrapper from '@utils/fetch';
import { data_v2_national_prices_path } from '@utils/routes';
import type { CountryCode } from '@utils/types/countries';

export const fetchM0Data = async (country: CountryCode | null) => {
  const response = await fetchWrapper(data_v2_national_prices_path({ country }));

  let json: NationalPrices | RequestErrorBody;

  try {
    json = await response.json();
  } catch (error) {
    throw new Error('errors.unknown_error');
  }

  if (!response.ok) {
    const code = 'code' in json && json.code ? json.code : 'errors.unknown_error';
    const message = 'message' in json && json.message ? json.message : 'errors.unknown_error';
    const errors = 'errors' in json && json.errors ? (json.errors as RequestError[]) : [];

    throw { code, message, errors } as RequestErrorBody;
  }

  return json as NationalPrices;
};
