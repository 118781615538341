import type { Unavailability } from '@utils/types/unavailability';
import { useForm } from 'react-hook-form';
import { convertUnavailabilityToForm } from './converters';
import type { CreateModalForm } from './types';

export const useUnavailabilityForm = (selectedUnavailability: Unavailability | null) => {
  const form = useForm<CreateModalForm>({
    values: selectedUnavailability ? convertUnavailabilityToForm(selectedUnavailability) : defaultValues,
  });

  return form;
};

export const defaultValues: CreateModalForm = {
  installation_uuid: null,
  start_date: null,
  end_date: null,
  p_max: null,
  event_type: null,
  capacity: {
    value: null,
    unit: 'MW',
  },
  comment: null,
  windmill_ids: [],
  unavailability_level: 'installation',
};
