import React, { useMemo } from 'react';
import { CustomOption, SelectTheme } from '@components/ui/multi-select';
import { useUser } from '@context/User.context';
import { filterOption } from '@GDM/forms/Select/filter-option';
import { Icon } from '@GDM/Icon';
import useBooks from '@hooks/requests/useBooks';
import { useBuyersWithContract } from '@hooks/requests/useBuyers';
import { useInstallationsLegacy } from '@hooks/requests/useInstallations';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import Book from '@utils/types/book';
import { EnergyType, Option } from '@utils/types/common-types';
import type { CountryCode } from '@utils/types/countries';
import { Installation } from '@utils/types/installation';
import MarketPlayer from '@utils/types/market_player';
import Select, { GroupBase, MultiValue, SingleValue } from 'react-select';
import { SearchbarCustomGroup } from '../SearchbarCustomGroup/SearchbarCustomGroup';
import styles from './searchbar.module.scss';
import SearchbarInputStyles from './SearchbarInputStyles';

export interface SearchbarOption extends Option {
  energy?: EnergyType | 'player';
  type?: 'book' | 'installation' | 'project' | 'market_player';
  hasMeter?: boolean;
  status?: Installation['status'];
  country?: CountryCode | null;
}

type SearchbarOptions = GroupBase<SearchbarOption>;

const getOptionsFromInstallations = (
  installations: Installation[],
  type: 'installation' | 'project',
): SearchbarOption[] => {
  if (!installations) return [];

  return installations
    .map((i) => ({
      label: i.name,
      value: i.name,
      energy: i.energy,
      type,
      hasMeter: i.has_meter,
      status: i.status,
      country: i.country,
    }))
    .sort(sortOptionsByLabelAsc);
};

const getOptionsFromBooks = (books: Book[]): SearchbarOption[] => {
  if (!books) return [];

  return books
    .map((b) => {
      const countries = b.installation_countries;
      const areAllCountriesTheSame = countries.every((country) => country === countries[0]);
      const country = countries && areAllCountriesTheSame ? countries[0] : null;

      return { label: b.name, value: b.name, energy: 'book', type: 'book', country } as SearchbarOption;
    })
    .sort(sortOptionsByLabelAsc);
};

const getOptionsFromMarketPlayers = (marketPlayers: MarketPlayer[]): SearchbarOption[] => {
  if (!marketPlayers) return [];

  return marketPlayers
    .map(
      (mp) =>
        ({
          label: mp.short_name,
          value: mp.id,
          energy: 'player',
          type: 'market_player',
          country: mp.country,
        } as SearchbarOption),
    )
    .sort(sortOptionsByLabelAsc);
};

const SearchbarInput = () => {
  const { t } = useTranslation();
  const user = useUser();
  const userIsSalesFocusOnly =
    user.isAuthorized(['display:view_salesfocus']) && !user.isAuthorized(['display:view_monitoring']);

  const { data: installations, loading: installationsLoading } = useInstallationsLegacy({
    type: 'operational_with_draft',
  });
  const { data: books, isLoading: booksLoading } = useBooks();
  const { data: projects, loading: projectsLoading } = useInstallationsLegacy({ type: 'lead' });
  const { data: marketPlayers, loading: marketPlayersLoading } = useBuyersWithContract();

  const isLoading = installationsLoading || booksLoading || projectsLoading || marketPlayersLoading;

  const options = useMemo<SearchbarOptions[]>(
    () => [
      { label: t('common.installations'), options: getOptionsFromInstallations(installations || [], 'installation') },
      { label: t('common.projects'), options: getOptionsFromInstallations(projects || [], 'project') },
      { label: t('common.books'), options: getOptionsFromBooks(books || []) },
      ...(user.isAuthorized(['display:view_salesfocus'])
        ? [
            {
              label: t('common.players'),
              options: getOptionsFromMarketPlayers(marketPlayers || []),
            },
          ]
        : []),
    ],
    [installations, books, projects, marketPlayers, t, user],
  );

  /**
   * Redirect to the correct page when an option is selected
   */
  const handleChange = (option: SearchbarOption) => {
    const encodedName = encodeURIComponent(option.value?.toString());

    if (option.type === 'book') {
      window.location.href = `/v2/dashboard_meter/?book=${encodedName}`;
    } else if (option.type === 'installation' && option.hasMeter && !userIsSalesFocusOnly) {
      window.location.href = `/v2/dashboard_meter/?meter=${encodedName}`;
    } else if ((option.type === 'installation' && !option.hasMeter) || userIsSalesFocusOnly) {
      window.location.href = `/v2/dashboard_meter/?installation=${encodedName}`;
    } else if (option.type === 'project') {
      window.location.href = `/v2/ppa_projects/${encodedName}`;
    } else if (option.type === 'market_player') {
      window.location.href = `/v2/market_players/${encodedName}`;
    }
  };

  return (
    <div className={styles['searchbar-input']}>
      <Icon name="Search" size={22} />

      <div className={styles['searchbar-input--search']}>
        <Select
          options={options}
          filterOption={filterOption}
          components={{
            Option: CustomOption,
            Group: SearchbarCustomGroup,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          closeMenuOnSelect={true}
          isMulti={false}
          theme={SelectTheme}
          styles={SearchbarInputStyles}
          isLoading={isLoading}
          classNamePrefix="main-search-bar"
          placeholder={t('search_bar.placeholder')}
          onChange={handleChange as (newValue: SingleValue<Option> | MultiValue<Option> | null) => void}
        />
      </div>
    </div>
  );
};

export default SearchbarInput;
