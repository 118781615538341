import type { Unavailability } from '@utils/types/unavailability';
import { computeAvailabilities } from './computeAvailabilities';

describe('computeAvailabilities', () => {
  it('should compute one unavailability', () => {
    const unavailabilities = [
      {
        start_date: '2025-04-21T10:00:00.000',
        end_date: '2025-04-21T15:00:00.000',
        real_capacity_value: 2.2,
      },
    ];

    const expected: [number, number][] = [
      [new Date('2025-04-21T00:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T01:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T02:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T03:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T04:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T05:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T06:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T07:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T08:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T09:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T10:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T11:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T12:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T13:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T14:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T15:00:00.000').getTime(), 190.2], // Last unavailability finish at 15:00 so it is not included
    ];

    const result = computeAvailabilities(
      unavailabilities as Unavailability[],
      190.2,
      new Date('2025-04-21T08:30:00.000'),
      new Date('2025-04-21T15:00:00.000'),
    );

    expect(result).toEqual(expected);
  });

  it('should compute multiple unavailabilities', () => {
    const unavailabilities = [
      {
        start_date: '2025-04-21T10:00:00.000',
        end_date: '2025-04-21T15:00:00.000',
        real_capacity_value: 2.2,
      },
      {
        start_date: '2025-04-21T08:30:00.000',
        end_date: '2025-04-21T10:00:00.000',
        real_capacity_value: 2.2,
      },
      {
        start_date: '2025-04-21T08:00:00.000',
        end_date: '2025-04-21T08:30:00.000',
        real_capacity_value: 2.2,
      },
    ];

    const expected: [number, number][] = [
      [new Date('2025-04-21T00:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T01:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T02:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T03:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T04:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T05:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T06:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T07:00:00.000').getTime(), 190.2],
      [new Date('2025-04-21T08:00:00.000').getTime(), 190.2 - 2.2 - 2.2],
      [new Date('2025-04-21T09:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T10:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T11:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T12:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T13:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T14:00:00.000').getTime(), 190.2 - 2.2],
      [new Date('2025-04-21T15:00:00.000').getTime(), 190.2], // Last unavailability finish at 15:00 so it is not included
    ];

    const result = computeAvailabilities(
      unavailabilities as Unavailability[],
      190.2,
      new Date('2025-04-21T08:30:00.000'),
      new Date('2025-04-21T15:00:00.000'),
    );

    expect(result).toEqual(expected);
  });

  it('should compute one unavailability with half hour data', () => {
    const unavailabilities = [
      {
        start_date: '2025-04-07T09:30:00.000',
        end_date: '2025-04-07T10:30:00.000',
        real_capacity_value: 3.6,
      },
    ];

    const expected: [number, number][] = [
      [new Date('2025-04-07T00:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T01:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T02:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T03:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T04:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T05:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T06:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T07:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T08:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T09:00:00.000').getTime(), 190.2 - 3.6],
      [new Date('2025-04-07T10:00:00.000').getTime(), 190.2 - 3.6],
      [new Date('2025-04-07T11:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T12:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T13:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T14:00:00.000').getTime(), 190.2],
      [new Date('2025-04-07T15:00:00.000').getTime(), 190.2],
    ];

    const result = computeAvailabilities(
      unavailabilities as Unavailability[],
      190.2,
      new Date('2025-04-07T08:30:00.000'),
      new Date('2025-04-07T15:00:00.000'),
    );

    expect(result).toEqual(expected);
  });
});
