import { getServiceStatus, manualFixServiceStatus } from '@api/service_status';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ServiceStatus } from '@utils/types/service-status';

export const useServiceStatus = () => {
  return useQuery<ServiceStatus[], Error>({
    queryKey: ['service_status'],
    queryFn: getServiceStatus,
  });
};

export const useManualFixServiceStatus = () => {
  return useMutation({
    mutationFn: manualFixServiceStatus,
  });
};
