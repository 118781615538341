import fetchWrapper, { type ReqError } from '@utils/fetch';
import { all_internal_service_status_index_path, manual_fix_internal_service_status_index_path } from '@utils/routes';
import { ServiceStatus } from '@utils/types/service-status';

export const getServiceStatus = async (): Promise<ServiceStatus[]> => {
  const response = await fetchWrapper(all_internal_service_status_index_path());
  const data = await response.json();

  if (!response.ok) {
    throw {
      code: data.error.code,
      message: data.error.message,
      errors: data.error.errors,
    } satisfies ReqError;
  }

  return data;
};

export const manualFixServiceStatus = async (id: string): Promise<ServiceStatus> => {
  const response = await fetchWrapper(manual_fix_internal_service_status_index_path(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw {
      code: data.error.code,
      message: data.error.message,
      errors: data.error.errors,
    } satisfies ReqError;
  }

  return data;
};
