import React, { useCallback } from 'react';
import { Restricted } from '@components/Restricted';
import { Button, DropDownButton } from '@GDM/Button';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { formatSystemDateTime } from '@utils/formatters/formatSystemDateTime';
import { export_csv_v2_unavailabilities_path } from '@utils/routes';
import type { Unavailability } from '@utils/types/unavailability';
import dayjs from 'dayjs';
import { type UseFormReturn } from 'react-hook-form';
import type { UnavailabilitiesFiltersType } from './Filters/useFilters';
import styles from './unavailabilities.module.scss';
import type { ToggleModal } from './UnavailabilitiesPageContent';
import { fetchCsv } from './utils/fetchCsv';

export const Actions = ({
  filteredUnavailabilities,
  filtersForm,
  toggleFormModal,
}: {
  filteredUnavailabilities: Unavailability[];
  filtersForm: UseFormReturn<UnavailabilitiesFiltersType>;
  toggleFormModal: ToggleModal;
}) => {
  const csvRequest = useRequest(export_csv_v2_unavailabilities_path(), fetchCsv, true);
  const { t } = useTranslation();

  const [startDate, endDate] = filtersForm.watch('dateRange');

  const onAddClick = useCallback(() => {
    toggleFormModal?.(null);
  }, [toggleFormModal]);

  const handleCsvDownload = (range: boolean) => {
    return () => {
      csvRequest.execute?.({
        params: {
          start_date: formatSystemDateTime(dayjs(startDate).startOf('day').toDate()),
          end_date: formatSystemDateTime(dayjs(endDate).add(1, 'day').startOf('day').toDate()),
          installations_name: Array.from(new Set(filteredUnavailabilities?.map((u) => u.installation.name) || [])),
          range,
        },
      });
    };
  };

  return (
    <div className={styles.actions}>
      <Restricted permissions={['display:edit_monitoring']}>
        <DropDownButton
          icon="Download"
          size="xxs"
          variant="primary-2"
          label={t('common.download')}
          isLoading={csvRequest.loading}
        >
          <Button variant="sub-button" text="common.csv_range" onClick={handleCsvDownload(true)} />
          <Button variant="sub-button" text="common.csv_overview" onClick={handleCsvDownload(false)} />
        </DropDownButton>
        <Button
          variant="primary-2"
          size="xxs"
          data-cy="add-new-unavailability"
          onClick={onAddClick}
          icon="Plus"
          className="ml-2"
        >
          {t('common.add_new')}
        </Button>
      </Restricted>
    </div>
  );
};
