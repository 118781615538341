import { fetchM0Data } from '@api/nationalPrices';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import type { CountryCode } from '@utils/types/countries';
import type { NationalPrices } from '../national-prices.types';

export const useM0Data = (country: CountryCode | null) => {
  return useQuery<NationalPrices, RequestErrorBody, NationalPrices>({
    queryKey: country ? ['m0Data', country] : ['m0Data'],
    queryFn: () => fetchM0Data(country),
  });
};
