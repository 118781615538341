import React, { useMemo, useRef } from 'react';
import { Chart, exportCSVChartAction } from '@GDM/Chart';
import { ChartRefContent } from '@GDM/Chart/chart.types';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { getDateInTimezone } from '@utils/date';
import type { Timezone } from '@utils/types/countries';
import type { Currency } from '@utils/types/currency';
import { AggregatedPriceData } from '@utils/types/price';

export const ImbalancePositiveChart = ({
  imbalance,
  spot,
  currency,
  timezone,
}: {
  imbalance: AggregatedPriceData[];
  spot: AggregatedPriceData[];
  currency: Currency;
  timezone: Timezone;
}) => {
  const { t } = useTranslation();
  const ref = useRef<ChartRefContent>(null);
  const currencySymbol = getSymbol(currency);
  const series = useMemo<Highcharts.Options['series']>(
    () => [
      {
        type: 'spline',
        name: t('sales_management.national_prices.chart.imbalance_avg'),
        data: imbalance.map((d) => [getDateInTimezone(d.date, timezone).valueOf(), d.average]),
        cropThreshold: 59999,
        lineWidth: 1.5,
        opacity: 1,
        tooltip: { valueSuffix: `${currencySymbol}/MWh` },
      },
      {
        type: 'spline',
        name: t('sales_management.national_prices.chart.spot_avg'),
        data: spot.map((d) => [getDateInTimezone(d.date, timezone).valueOf(), d.average]),
        cropThreshold: 59999,
        lineWidth: 1.5,
        opacity: 0.5,
        tooltip: { valueSuffix: `${currencySymbol}/MWh` },
      },
      {
        type: 'spline',
        name: t('sales_management.national_prices.chart.imbalance_q90'),
        data: imbalance.map((d) => [getDateInTimezone(d.date, timezone).valueOf(), d.max]),
        cropThreshold: 59999,
        lineWidth: 1.5,
        opacity: 0.3,
        tooltip: { valueSuffix: `${currencySymbol}/MWh` },
      },
      {
        type: 'spline',
        name: t('sales_management.national_prices.chart.imbalance_q10'),
        data: imbalance.map((d) => [getDateInTimezone(d.date, timezone).valueOf(), d.min]),
        cropThreshold: 59999,
        lineWidth: 1.5,
        opacity: 0.3,
        tooltip: { valueSuffix: `${currencySymbol}/MWh` },
      },
    ],
    [imbalance, spot, t, currencySymbol, timezone],
  );

  const options: Highcharts.Options = {
    chart: { type: 'spline', height: 300 },
    xAxis: { gridLineWidth: 1, crosshair: true },
    yAxis: {
      title: { text: t('sales_management.imbalance_unit', { unit: `${currencySymbol}/MWh` }) },
      gridLineWidth: 1,
    },
    exporting: { filename: 'imbalance_prices' },
  };

  return (
    <Chart
      options={options}
      series={series}
      timezone={timezone}
      hideLegend
      ref={ref}
      handleDownloadCsv={exportCSVChartAction(ref)}
    />
  );
};
