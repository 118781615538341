import React, { useEffect, useMemo } from 'react';
import ControlledDatePicker from '@components/FormInputs/ControlledDatePicker';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { ERRORS_TO_REMAP } from '@GDM/FormErrors/errors-to-remap';
import inputStyles from '@GDM/forms/Input/input.module.scss';
import { Col, Row } from '@GDM/layout';
import { Modal } from '@GDM/Modal';
import { useUnavailabilityCreateMutation, useUnavailabilityUpdateMutation } from '@hooks/requests/useUnavailabilities';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { Option } from '@utils/types/common-types';
import type Installation from '@utils/types/installation';
import { CapacityUnit, Unavailability } from '@utils/types/unavailability';
import type { Path, UseFormReturn } from 'react-hook-form';
import type { ToggleModal } from '../UnavailabilitiesPageContent';
import { CAPACITY_UNIT_OPTIONS, EVENT_TYPES_OPTIONS } from '../utils/constants';
import { useUnavailabilitiesInstallations } from '../utils/useUnavailabilitiesInstallations';
import { convertFormToBody } from './converters';
import { CREATE_MODAL_FORM_FIELDS, CreateModalForm } from './types';

const capacityExceedsPmaxCheck = (
  capacity_value: number | null,
  capacity_unit: CapacityUnit,
  p_max: number | null,
): boolean => !!capacity_value && !!p_max && capacity_unit !== 'percentage' && capacity_value > p_max;

export const CreateModal = ({
  selectedUnavailability,
  toggle,
  isOpen,
  startDate,
  endDate,
  form: { watch, setValue, setError, control, handleSubmit },
}: {
  selectedUnavailability: Unavailability | null;
  toggle: ToggleModal;
  isOpen: boolean;
  form: UseFormReturn<CreateModalForm>;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { t } = useTranslation();
  const { data: installations } = useUnavailabilitiesInstallations();
  const selectedInstallation = installations?.find((installation) => installation.uuid === watch('installation_uuid'));
  const values = watch();

  const updateMutation = useUnavailabilityUpdateMutation({ start_date: startDate, end_date: endDate }, values);
  const createMutation = useUnavailabilityCreateMutation({ start_date: startDate, end_date: endDate }, values);

  const capacityExceedsPmax = capacityExceedsPmaxCheck(
    watch('capacity')?.value,
    watch('capacity')?.unit,
    watch('p_max'),
  );

  const isWindmillUnavailability = watch('unavailability_level') === 'windmill';

  const onSubmit = (data: CreateModalForm) => {
    if (selectedUnavailability) {
      updateMutation.mutate(
        { body: convertFormToBody(data), uuid: selectedUnavailability.uuid },
        { onSuccess: () => toggle(null) },
      );
    } else {
      createMutation.mutate(convertFormToBody(data), { onSuccess: () => toggle(null) });
    }
  };

  useEffect(() => {
    const subscription = watch(({ installation_uuid, windmill_ids }, { name, type }) => {
      if (name === 'installation_uuid' || name === 'unavailability_level') {
        setValue('windmill_ids', []);
      }

      /**
       * We could use a deferred hook to avoid getting the selected installation twice, but the performance will
       * always be good, because at most we will have few thousands of installations
       * While using too much state can have too many side effects or performance issues
       */
      const installation = installations?.find((installation) => installation.uuid === installation_uuid);

      if (name === 'installation_uuid' && type === 'change') {
        if (installation) {
          setValue(
            'p_max',
            installation.p_max
              ? (installation.p_max as number) / 1000 // to convert to MW
              : null,
          );
        }
      }

      if (name === 'windmill_ids' && type === 'change') {
        const windmills =
          installation?.windmills?.filter((windmill) => windmill_ids?.includes(windmill.id.toString())) || [];

        const totalCapacity = windmills.reduce((acc, windmill) => acc + (windmill.power ?? 0), 0) / 1000;

        setValue('capacity.value', totalCapacity);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, setValue, installations]);

  const isPending = [updateMutation.isPending, createMutation.isPending].some(Boolean);
  const errorMessage = [updateMutation.error?.message, createMutation.error?.message].find(Boolean);
  const errors = createMutation.error?.errors || updateMutation.error?.errors;

  useEffect(() => {
    if (errors) {
      errors.forEach(({ field, code, date, count }) => {
        if (field && CREATE_MODAL_FORM_FIELDS.includes(field) && code) {
          setError(field as Path<CreateModalForm>, {
            message: ERRORS_TO_REMAP[code]
              ? t(ERRORS_TO_REMAP[code], { prop: `<code>${field}</code>`, date, count })
              : code,
          });
        }
      });
    }
  }, [errors, setError, t]);

  const installationOptions = useMemo(() => getInstallationOptions(installations || []), [installations]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(null)}
      header={selectedUnavailability ? 'monitoring.unavailabilities.edit' : 'monitoring.unavailabilities.create'}
      submitAction={handleSubmit(onSubmit)}
      isLoading={isPending}
      error={(capacityExceedsPmax ? 'monitoring.unavailabilities.errors.capacity_exceeds_pmax' : null) || errorMessage}
    >
      <div className="d-flex flex-column gap-3">
        <Row>
          <Col md={6}>
            <ControlledSelect
              control={control}
              name="installation_uuid"
              label="common.installation"
              isInstallationOrBook
              options={installationOptions}
              rules={{ required: true }}
              size="lg"
            />
          </Col>
          <Col md={6}>
            <ControlledSelect
              control={control}
              name="event_type"
              label="monitoring.unavailabilities.event_type"
              options={EVENT_TYPES_OPTIONS}
              rules={{ required: true }}
              size="lg"
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControlledDatePicker
              control={control}
              name="start_date"
              label="common.start"
              picks="datetime"
              size="lg"
              rules={{ required: true }}
            />
          </Col>
          <Col md={6}>
            <ControlledDatePicker
              control={control}
              name="end_date"
              rules={{ required: true }}
              minDate={watch('start_date')}
              label="common.end"
              picks="datetime"
              size="lg"
            />
          </Col>
        </Row>

        {selectedInstallation?.energy === 'wind' && (
          <>
            <Row>
              <Col sm={12}>
                <ControlledRadioButtons
                  control={control}
                  name="unavailability_level"
                  label="monitoring.unavailabilities.unavailability_level"
                  options={[
                    { label: 'monitoring.unavailabilities.installation_level', value: 'installation' },
                    { label: 'monitoring.unavailabilities.windmill_level', value: 'windmill' },
                  ]}
                  size="lg"
                />
              </Col>
            </Row>

            {isWindmillUnavailability && (
              <Row>
                <Col sm={12}>
                  <ControlledSelect
                    control={control}
                    name="windmill_ids"
                    label="monitoring.unavailabilities.unavailable_windmills"
                    options={getWindmillOptions(selectedInstallation || null)}
                    isMulti
                    size="lg"
                    disabled={selectedInstallation?.windmills?.length === 0}
                    rules={{ required: true }}
                    isClearable
                    showAllTags
                  />
                </Col>
              </Row>
            )}
          </>
        )}

        <Row>
          <Col sm={6}>
            <ControlledInput
              control={control}
              containerClassName={inputStyles['has-select']}
              type="number"
              name="capacity.value"
              label="monitoring.unavailabilities.capacity_value"
              disabled={isWindmillUnavailability}
              step={0.001}
              size="lg"
              rules={{
                required: !isWindmillUnavailability,
                min: 0,
                max: watch('capacity.unit') === 'percentage' ? 100 : watch('p_max') || Infinity,
              }}
              suffix={
                <ControlledSelect
                  control={control}
                  name="capacity.unit"
                  options={CAPACITY_UNIT_OPTIONS}
                  size="lg"
                  isDisabled={isWindmillUnavailability}
                  disabled={isWindmillUnavailability}
                  rules={{ required: !isWindmillUnavailability }}
                  className={inputStyles.select}
                />
              }
            />
          </Col>
          <Col sm={6}>
            <ControlledInput
              control={control}
              name="p_max"
              type="number"
              autoComplete="off"
              label="monitoring.unavailabilities.p_max"
              suffix="MW"
              disabled={isWindmillUnavailability}
              rules={{ required: !isWindmillUnavailability }}
              step={0.001}
              size="lg"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <ControlledInput
              control={control}
              name="comment"
              label="monitoring.unavailabilities.comment"
              full
              size="lg"
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const getInstallationOptions = (installations: Installation[]) => {
  return installations
    .map(({ name: label, uuid: value, energy }) => ({ label, value, energy }))
    .sort(sortOptionsByLabelAsc);
};

const getWindmillOptions = (installation: Installation | null): Option<string>[] => {
  if (!installation || !installation.windmills) return [];

  return installation.windmills
    .map((windmill) => ({
      value: windmill.id.toString(),
      label: windmill.name || windmill.turbine.name,
    }))
    .sort(sortOptionsByLabelAsc);
};
