import React from 'react';
import useTranslation from '@hooks/useTranslation';
import type { CellContext } from '@tanstack/react-table';
import type { Unavailability } from '@utils/types/unavailability';

export const SourceCell = ({ row: { original } }: React.PropsWithChildren<CellContext<Unavailability, unknown>>) => {
  const { t } = useTranslation();

  return t(`monitoring.unavailabilities.sources.${original.source}`);
};
