import React, { useMemo } from 'react';
import { Row } from '@GDM/layout';
import type Installation from '@utils/types/installation';
import type { Unavailability } from '@utils/types/unavailability';
import { FormProvider, type UseFormReturn } from 'react-hook-form';
import { Actions } from './Actions';
import { AvailabilityCards } from './AvailabilityCards';
import { AvailabilityChart } from './AvailabilityChart';
import { UnavailabilityFilters } from './Filters/Filters';
import type { UnavailabilitiesFiltersType } from './Filters/useFilters';
import type { ToggleModal } from './UnavailabilitiesPageContent';
import { computeAvailabilities, computeTotalPmax } from './utils/computeAvailabilities';

export const Header = ({
  filteredInstallations,
  filteredUnavailabilities,
  unavailabilities,
  filtersForm,
  toggleFormModal,
  isLoading,
  startDate,
  endDate,
}: {
  filteredInstallations: Installation[];
  filteredUnavailabilities: Unavailability[];
  unavailabilities: Unavailability[];
  filtersForm: UseFormReturn<UnavailabilitiesFiltersType>;
  toggleFormModal: ToggleModal;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const chartVisible = filtersForm.watch('chartVisible', false);

  const total_p_max = useMemo(() => {
    return computeTotalPmax(filteredInstallations);
  }, [filteredInstallations]);

  const availabilities = useMemo(
    () => computeAvailabilities(filteredUnavailabilities, total_p_max, startDate, endDate),
    [filteredUnavailabilities, total_p_max, startDate, endDate],
  );

  return (
    <>
      <FormProvider {...filtersForm}>
        <UnavailabilityFilters
          filtersForm={filtersForm}
          filteredUnavailabilities={filteredUnavailabilities}
          unavailabilities={unavailabilities}
        />
      </FormProvider>
      <Row>
        {chartVisible && <AvailabilityChart availabilities={availabilities} />}
        <AvailabilityCards
          availabilities={availabilities}
          total_p_max={total_p_max}
          chartVisible={chartVisible}
          isLoading={isLoading}
        />
      </Row>
      <div className="mb-2">
        <Actions
          filteredUnavailabilities={filteredUnavailabilities}
          filtersForm={filtersForm}
          toggleFormModal={toggleFormModal}
        />
      </div>
    </>
  );
};
